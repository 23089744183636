import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes from '../routes';
import Navbar from './Navbar';
import { authenticationService } from '../services/auth.service';
import { useEffect, useState } from 'react';
import { AuthProvider } from './auth/AuthContext';
import Login from '../pages/login/Login';
import NewPassword from '../pages/new-password/NewPassword';
import Home from '../pages/home/Home';
import ProtectedRoute from './auth/ProtectedRoute';
import MemberCredentialRequestList from '../pages/member/member-credential-request-list/MemberCredentialRequestList';
import MemberBaptismList from '../pages/member/member-baptism-list/MemberBaptismList';
import NotFound from '../pages/not-found/NotFound';
import MemberDeletedList from '../pages/member/member-deleted-list/MemberDeletedList';
import MemberAddEdit from '../pages/member/member-add-edit/MemberAddEdit';
import MemberList from '../pages/member/member-list/MemberList';
import AnnuityList from '../pages/secretary/annuity-list/AnnuityList';
import DashboardSecretary from '../pages/dashboard-secretary/DashboardSecretary';
import CongregationAddEdit from '../pages/congregation/congregation-add-edit/CongregationAddEdit';
import CongregationList from '../pages/congregation/congregation-list/CongregationList';
import EventPersonEdit from '../pages/event-person/event-person-edit/EventPersonEdit';
import EventPersonList from '../pages/event-person/event-person-list/EventPersonList';
import EventPrintList from '../pages/event-person/event-print-list/EventPrintList';
import EventRunning from '../pages/event-person/event-running/EventRunning';
import DashboardEvent from '../pages/event-person/dashboard-event/DashboardEvent';
import UserList from '../pages/user/user-list/UserList';
import MeetingMemberList from '../pages/meeting/meeting-member-list/MeetingMemberList';
import MeetingAdd from '../pages/meeting/meeting-add/MeetingAdd';
import MeetingRunning from '../pages/meeting/meeting-running/MeetingRunning';
import MeetingList from '../pages/meeting/meeting-list/MeetingList';
import MeetingEdit from '../pages/meeting/meeting-edit/MeetingEdit';
import ScheduleCalendar from '../pages/schedule/schedule-calendar/ScheduleCalendar';
import LetterList from '../pages/secretary/letter-list/LetterList';

function Routes() {
    const [userLogged, setUserLogged] = useState(false);

    useEffect(()=> {
        authenticationService.currentUser.subscribe(value => {
            setUserLogged(value != null);
        });
    }, []);

    return (
        <AuthProvider>
            <Router>
                { userLogged && <Navbar></Navbar> }
                <div className={userLogged ? 'uk-container': ''}>
                    <Switch>
                        <Route path="/entrar" component={Login} />
                        <Route path="/nova-senha" component={NewPassword} />
                        <Route path="/" exact component={Home} />
                        <ProtectedRoute path="/inicio" component={Home} />
                        <ProtectedRoute path="/membros/credenciais-solicitadas" component={MemberCredentialRequestList} />
                        <ProtectedRoute path="/membros/batismo/lista" component={MemberBaptismList} />
                        <ProtectedRoute path="/membros/removidos" component={MemberDeletedList} />
                        <ProtectedRoute path="/membros/novo" component={MemberAddEdit} />
                        <ProtectedRoute path="/membros/:id" component={MemberAddEdit} />
                        <ProtectedRoute path="/membros" component={MemberList} />
                        <ProtectedRoute path="/secretaria/anuidades" component={AnnuityList} />
                        <ProtectedRoute path="/secretaria/cartas" component={LetterList} />
                        <ProtectedRoute path="/secretaria" component={DashboardSecretary} />
                        <ProtectedRoute path="/congregacoes/novo" component={CongregationAddEdit} />
                        <ProtectedRoute path="/congregacoes/:id" component={CongregationAddEdit} />
                        <ProtectedRoute path="/congregacoes" component={CongregationList} />
                        <ProtectedRoute path="/eventos/pessoas/:id" component={EventPersonEdit} />
                        <ProtectedRoute path="/eventos/pessoas" component={EventPersonList} />
                        <ProtectedRoute path="/eventos/impressoes" component={EventPrintList} />
                        <ProtectedRoute path="/eventos/em-andamento" component={EventRunning} />
                        <ProtectedRoute path="/eventos" component={DashboardEvent} />
                        <ProtectedRoute path="/usuarios" component={UserList} />
                        <ProtectedRoute path="/reuniao/:id/membros" component={MeetingMemberList} />
                        <ProtectedRoute path="/reuniao/nova" component={MeetingAdd} />
                        <ProtectedRoute path="/reuniao/em-andamento" component={MeetingRunning} />
                        <ProtectedRoute path="/reuniao/lista" component={MeetingList} />
                        <ProtectedRoute path="/reuniao/:id" component={MeetingEdit} />
                        <ProtectedRoute path="/calendario" component={ScheduleCalendar} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default Routes;