import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import UserLogin from "../../../models/UserLogin";
import {
  faPencil,
  faPlus,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogConfirm from "../../../components/DialogConfirm";

interface CongListDTO {
  id: number;
  description: string;
}

export default function CongregationList() {
  const history = useHistory();
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [isAuxSec, setAuxSec] = useState<boolean>();
  const [termSearch, setTermSearch] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>();
  const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
  const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
  var congSelected = useRef(0);
  const [totalCongs, setTotalCongs] = useState(0);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState("");

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: CongListDTO) => <span>{row.description}</span>,
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: CongListDTO) => (
        <div>
          {isReadOnly ? (
            <Link
              to={`/congregacoes/${row.id}`}
              uk-tooltip="title: Visualizar"
              className="uk-margin-right"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
          ) : (
            <Link
              to={`/congregacoes/${row.id}`}
              uk-tooltip="title: Editar"
              className="uk-margin-right"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Link>
          )}
          {!isReadOnly && (
            <a
              href="#"
              uk-tooltip="title: Inativar"
              className="uk-margin-right"
              onClick={(ev) => {
                ev.preventDefault();
                handleRemove(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>
          )}
        </div>
      )
    },
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    setAuxSec(authenticationService.isAuxSec());
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const fetchCongregations = async (page: number, limit: number) => {
    try {
      setTotalCongs(0);
      if (termSearch) {
        const responseSearch = await api.get(
          `congregations/description/${termSearch.toUpperCase()}`
        );
        setTotalCongs(responseSearch.data.total);
        return responseSearch;
      }

      const response = await api.get(`congregations/list`, {
        params: {
          page: page,
          limit: limit,
          user: authenticationService.isAuxSec()
            ? (authenticationService.currentUserValue as UserLogin).id
            : null,
        },
      });

      setTotalCongs(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleRemove = async (row: CongListDTO) => {
    setDialogDeleteText([
      `Deseja realmente invativar ${row.description}?`,
      'Caso tenha membros nesta congregação, eles devem ser movitos para outra congregação antes de inativar esta.',
      "Confirme sua senha se tiver certeza",
    ]);
    congSelected.current = row.id;
    setIsDialogDelete(true);
  };

  const handleSearchByName = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const dialogClose = () => {
    setIsDialogDelete(false);
  };

  const dialogConfirm = async (confirmationCode: string) => {
    if (!confirmationCode) {
      toast.warning("Informe a senha", toastOptions);
      return;
    }

    try {
      const dto = { confirmationCode: confirmationCode };

      await api.post(`congregations/${congSelected.current}/delete`, dto);
      setIsDialogDelete(false);
      toast.success("Congregação inativada com sucesso!", toastOptions);
      setRefresh(!refresh);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  return (
    <div id="page-congregation">
      <div className="uk-text-large">
        <div className="uk-flex uk-flex-middle">
          Congregações Ativas
          {(!isReadOnly || isAuxSec) && (
            <div className="uk-flex uk-margin-small-left">
              <div className="uk-inline-clip uk-transition-toggle">
                <div className="uk-transition-scale-up uk-transition-opaque">
                  <Link
                    to="/congregacoes/novo"
                    className="uk-button uk-button-primary"
                  >
                    {`Adicionar `} <FontAwesomeIcon icon={faPlus} size="xl" />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="uk-margin-top">
        <div className="uk-grid-small" uk-grid="true">
          <div className="uk-width-1-4@m uk-flex uk-flex-middle">
            <div>Congregações: {totalCongs}</div>
          </div>
          <div className="uk-search uk-search-default uk-width-3-4@m">
            <span className="uk-search-icon-flip" uk-search-icon="true"></span>
            <input
              className="uk-search-input uk-form-small"
              type="search"
              placeholder="Pesquise por nome..."
              style={{ textTransform: "uppercase" }}
              onChange={(ev) => {
                handleSearchByName(ev.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <TablePagination
        columns={columns}
        fetchData={fetchCongregations}
        refresh={refresh}
      />
      <DialogConfirm
        open={isDialogDelete}
        text={dialogDeleteText}
        afterClose={dialogClose}
        confirmTextLabel={"Informe sua senha"}
        handleConfirmation={(confirmationCode) =>
          dialogConfirm(confirmationCode)
        }
      />
    </div>
  );
}
