import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import Param from "../../../models/Param";
import { utils } from "../../../utils/utils";
import TablePagination from "../../../components/TablePagination";
import { faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showAnnuityTypeFile } from "../../../utils/masks";
import { AnnuityTypeFile } from "../../../models/enums/AnnuityTypeFile";
import moment from "moment";

interface MemberListDTO {
  id: number;
  name: string;
  ecclesiasticalFunction: any;
  congregation: any;
  historic: any;
}

export default function AnnuityList() {
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMembers, setTotalMembers] = useState(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [termSearch, setTermSearch] = useState<string>("");
  const [congregations, setCongregations] = useState<Param[]>([]);
  const [congSearch, setCongSearch] = useState<number>(0);
  const [type, setType] = useState<string>();
  const [open, setOpen] = useState<boolean>(true);
  const [years, setYears] = useState<number[]>([]);
  const initialYear = useRef<number>(2024);
  const [currentYear, setCurrentYear] = useState<number>(moment().year());
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: MemberListDTO) => <span>{row.name}</span>
    },
    {
      name: "Função",
      selector: "ecclesiasticalFunction.description",
      sortable: true,
    },
    {
      name: "Congregação",
      sortable: true,
      cell: (row: MemberListDTO) => <span>{row.congregation.description}</span>
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: MemberListDTO, index: number) => (
        <div>
          <Link
            to={`/membros/${row.id}`}
            target={"blank"}
            uk-tooltip="title: Visualizar"
            className='uk-margin-small-right' 
          >
            <FontAwesomeIcon icon={faSearch} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);
  
  const init = async () => {
    const dataCongregations = await api.get("congregations/dashboard");
    setCongregations(dataCongregations.data);
    handleYears();
  };

  const handleYears = () => {
    setYears([]);

    let initYear = currentYear;

    if (moment().month() == 11) {
      initYear++;
    }

    for (let i = initYear; i >= initialYear.current; i--) {
        setYears(array => [...array, i]);
    }
  }

  const fetchMembers = async (page: number, limit: number) => {
    try {
      setTotalMembers(0);

      let params = {
        page: page,
        limit: limit,
        idCongregation: congSearch,
        year: currentYear,
        type: type,
        open: open
      } as any;

      if (termSearch) {
        params = { ...params, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`members/annuities`, {
        params: params
      });

      setTotalMembers(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handlePrint = async () => {
    setIsRunning(true);
    try {
      const { data } = await api.post("/members/annuities/report", {
        name: termSearch?.toUpperCase(),
        idCongregation: congSearch,
        year: currentYear,
        type: type,
        open: open
      });
      const name = `lista-anuidades-${currentYear}-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  };

  const handleSearch = async (search: string, congregation: number) => {
    await setCongSearch(congregation);

    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleYear = async(newYear: number) => {
    setCurrentYear(newYear);
    setRefresh(!refresh);
  }

  const handleType = async(selectedType: string) => {
    setType(selectedType);
    setRefresh(!refresh);
  }

  const handleOpen = async(selected: boolean) => {
    setOpen(selected);
    setRefresh(!refresh);
  }
  
  return (
    <div id="page-annuity-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Anuidades
              </h3>
              <h5 className="uk-text-meta uk-text-small uk-margin-remove-top">
                Opção [Somente Em Aberto] retornará o ministro que estiver com pelo menos uma das convenções em aberto
              </h5>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/secretaria"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-flex uk-flex-middle">
            <div className="uk-margin-right">Total: {totalMembers}</div>
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small uk-flex uk-flex-middle"
              disabled={isRunning || totalMembers == 0}
              onClick={() => handlePrint()}
            >
              <span>
                Imprimir Lista
              </span>
              {isRunning ? (
                <div
                  className="uk-margin-small-left"
                  uk-spinner="true; ratio:0.5"
                ></div>
              ) : (
                <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} />
              )}
            </button>
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearch(ev.target.value, congSearch);
                }}
              />
            </div>
            <div className="uk-width-1-2@m">
              <label
                className="uk-form-label uk-margin-right"
                htmlFor="congregationId"
              >
                Congregação
              </label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-width-3-4@m"
              >
                <select
                  className="uk-form-small"
                  id="congregationId"
                  name="congregationId"
                  value={congSearch}
                  onChange={(e) => handleSearch(termSearch, +e.target.value)}
                >
                  <option value="" key={`congregation0`}>
                    Todas
                  </option>
                  {congregations.map((f) => {
                    return (
                      <option value={f.id} key={`congregation${f.id}`}>
                        {f.description}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
            <div className="uk-search uk-search-default uk-width-1-4@m">
              <div className="uk-flex uk-flex-middle">                
                <label className="uk-form-label uk-margin-small-right" htmlFor="year">Ano</label>
                <div className="uk-form-controls">
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                    <select className="uk-form-small"
                      id="year" 
                      placeholder="Ano"
                      name="year"
                      value={currentYear}
                      onChange={(ev) => handleYear(+(ev.target.value))}
                    >       
                      {
                        years?.map(y => {
                            return <option key={`ano${y}`} value={`${y}`}>{y}</option>
                        })
                      }  
                    </select>
                    <button 
                      className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                      style={{textTransform: 'none'}} 
                      type="button" 
                      tabIndex={-1}
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-4@m">
              <div className="uk-flex uk-flex-middle">                
                <label className="uk-form-label uk-margin-small-right" htmlFor="type">Convenção</label>
                <div uk-form-custom="target: > * > span:first-child" 
                  className="uk-width-1"
                  style={{backgroundColor: '#FFF'}}>
                  <select className="uk-form-small"
                    id="type" 
                    placeholder="Convenção"
                    name="type"
                    value={type}
                    onChange={(e) => {handleType(e.target.value);}}
                  >                                    
                    <option value="">Todas</option>
                    <option value={AnnuityTypeFile.CGADB}>{showAnnuityTypeFile(AnnuityTypeFile.CGADB)}</option>
                    <option value={AnnuityTypeFile.CONFRADESP}>{showAnnuityTypeFile(AnnuityTypeFile.CONFRADESP)}</option>
                    <option value={AnnuityTypeFile.OTHER}>{showAnnuityTypeFile(AnnuityTypeFile.OTHER)}</option>
                  </select>
                  <button 
                    className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                    style={{textTransform: 'none'}} 
                    type="button" 
                    tabIndex={-1}
                  >
                    <span></span>
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="uk-width-1-4@m">
              <label className="uk-form-label uk-margin-small-right" htmlFor="type">Somente Em Aberto?</label>
                <input className="uk-checkbox uk-margin-left" 
                  type="checkbox"    
                  id="open"
                  checked={open}
                  onChange={(e) => {
                    handleOpen(e.target.checked);
                  }}/>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchMembers}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
