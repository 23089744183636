import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Member from '../../../models/Member';
import toastOptions from '../../../utils/toastOptions';
import { displayDateTime, handleFocus } from '../../../utils/masks';
import { Link, useHistory, useParams } from 'react-router-dom';

import { authenticationService } from '../../../services/auth.service';
import { Editor } from 'react-draft-wysiwyg';

import './news-add-edit.css';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import INews from '../../../models/INews';
import draftToHtml from 'draftjs-to-html';

interface MemberParams {
    id: string;
}

export default function NewsAddEdit() {
    const params = useParams<MemberParams>();
    const history = useHistory();
    const [news, setNews] = useState<INews>();    
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [isAdding, setAdding] = useState<boolean>(true);
    
    const schema = yup.object().shape({
        title: yup.string().required('Título obrigatório')
        .max(100, (val) => `Máximo ${val.max} caracteres`)
        .min(3, (val) => `Mínimo ${val.min} caracteres`)
    });

    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(schema),
    });

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    useEffect(() => {        
        const init = async()=> {
            
            const adding = +(params.id) ? +(params.id) <= 0: true;
            setAdding(adding);

            if (!adding) {
                handleEdit(params.id);
            }

            setReadOnly(authenticationService.isReadOnly());
        }
    
        init();        
    }, [params.id]);

    const handleEdit = async (id: string) => {
        try {
            const { data } = await api.get(`news/${id}`);
        
            setNews(data.item);

            const blocksFromHTML = convertFromHTML(data.item.body);
            if (blocksFromHTML) {
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                );
                setEditorState(EditorState.createWithContent(contentState));
            }
            
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.warning(m, toastOptions);
            });
        }
    }
    
    const onSubmit = async (news: INews) => {
        try {
            if (editorState) {
                news.body = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
            }

            if (isAdding) {
                const { data } = await api.post('news', news);
                toast.success('Notícia inserida com sucesso!', toastOptions);

                history.replace(`/noticias/${data.item.id}`);
            } else {
                news.id = +(params.id);
                const { data: result } = await api.put('news', news);
                toast.success('Notícia atualizada com sucesso!', toastOptions);

                const m = Object.assign(news, {});
                result.item.item.id = 0;
                setNews(result.item.item);
                result.item.item.id = m.id;
                setNews(result.item.item);
            }
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }        

    
    return (
        ((params.id && !news)) ? (
            <div className="uk-flex uk-flex-center">
                <div uk-spinner="true; ratio:2">Carregando...</div>
            </div>
        ) :

        <div id="page-add-edit-member">    
            <form onSubmit={handleSubmit<INews>(onSubmit)} onReset={reset}>
                <div className="uk-modal-body">
                    <div className="uk-grid-small" uk-grid="true">
                        <div className="uk-width-1-1@s">
                            <label className="uk-form-label" htmlFor="title">Título</label>
                            <input type="text" 
                                id="title" 
                                name="title" 
                                placeholder="Título da Notícia" 
                                className="uk-input uk-form-small" 
                                defaultValue={news?.title}
                                autoComplete={"off"}
                                onFocus={handleFocus}
                                autoFocus={true}
                                ref={register} />
                            <small className="uk-text-danger">{errors.title?.message}</small>
                        </div>                        
                        <div className="uk-width-1-1@s">
                            <Editor 
                                editorState={editorState}
                                onEditorStateChange={(editorState) => setEditorState(editorState)}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"                                                 
                                toolbar={{
                                    inline: { inDropdown: false },
                                    list: { inDropdown: false },
                                    textAlign: { inDropdown: false },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true }
                                }}
                            />
                        </div>
                    </div>
                    {
                        (news?.id && authenticationService.canChange()) && (
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-2@s">
                                    <span className="uk-text-italic uk-text-small">Adicionado por:</span>
                                    <span className="uk-text-muted uk-text-small uk-margin-small-left">
                                        {`${news?.createdBy} - ${displayDateTime(news?.createdDate)}`}
                                    </span>                                    
                                </div>  
                                {
                                    news.modifiedBy &&
                                        <div className="uk-width-1-2@s">
                                            <span className="uk-text-italic uk-text-small">Alterado por:</span>
                                            <span className="uk-text-muted uk-text-small uk-margin-small-left">
                                                {`${news?.modifiedBy} - ${displayDateTime(news?.modifiedDate)}`}
                                            </span> 
                                        </div>  
                                }     
                            </div>
                        )
                    }
                </div>
                <div className="uk-modal-footer uk-margin-bottom">
                    <div className="uk-grid-small uk-flex uk-flex-center" uk-grid="true">
                        <div className="uk-text-center">   
                            <Link to="/aplicativo" className="uk-button uk-button-default uk-margin-left uk-width-1-1">
                                Voltar
                            </Link>
                        </div>
                        <div className="uk-text-center">
                            <button 
                                id="btn-save"
                                disabled={formState.isSubmitting || isReadOnly
                                }
                                className="uk-button uk-button-primary uk-margin-left uk-width-1-1" 
                                type="submit">                                    
                                {formState.isSubmitting && <div uk-spinner="true"></div>}
                                Salvar
                            </button>
                        </div>
                        <div className="uk-text-center">                            
                        </div>
                    </div>
                    <div className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center" uk-grid="true">
                    </div>
                </div>
            </form>
       </div>
    )
}