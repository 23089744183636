import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import { displayDateTime } from "../../../utils/masks";
import { utils } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

interface MemberListDTO {
  idMember: number;
  memberName: string;
  ecclesiasticalFunction: string;
  congregation: string;
  timeArrived: Date;
}

interface MeetingParams {
  id: string;
}

export default function MeetingMemberList() {
  const params = useParams<MeetingParams>();
  const [refresh, setRefresh] = useState<boolean>();
  const [total, setTotal] = useState(0);
  const [termSearch, setTermSearch] = useState<string>("");
  const [isPriting, setPrinting] = useState<boolean>(false);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
  const [groupByCong, setGroupByCong] = useState<boolean>(false);

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: MemberListDTO) => <span>{row.memberName}</span>,
    },
    {
      name: "Função",
      selector: "ecclesiasticalFunction",
      sortable: true,
    },
    {
      name: "Congregação",
      sortable: true,
      cell: (row: MemberListDTO) => <span>{row.congregation}</span>,
    },
    {
      name: "Entrada",
      sortable: true,
      cell: (row: MemberListDTO) => (
        <span>{displayDateTime(row.timeArrived)}</span>
      ),
    },
  ];

  useEffect(() => {
    setTotal(0);
  }, []);

  const fetchMembers = async (page: number, limit: number) => {
    try {

      let url = `meetings/${params.id}/members`;

      if (termSearch) {
        url += `/name/${termSearch.toUpperCase()}`;
      }

      const response = await api.get(url, {
        params: {
          page: page,
          limit: limit,
        }
      });

      if (total == 0) {
        setTotal(response.data.total);
      }

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const handleSearch = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handlePrint = async () => {
    setPrinting(true);
    try {
      const { data } = await api.get(`/meetings/${params.id}/members/report`, {
        params: {
          groupByCongregation: groupByCong
        }
      });
      const name = `lista-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setPrinting(false);
    }
  };

  return (
    <div id="page-meeting-member-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Membros Presentes na Reunião
              </h3>
              Presentes: {total}
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={`/reuniao/${params.id}`}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className=" uk-flex uk-flex-middle">
            <div className="uk-search uk-search-default uk-width-1-2">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                disabled={isPriting}
                onChange={(ev) => {
                  handleSearch(ev.target.value);
                }}
              />
            </div>
            <div className="uk-width-1-4 uk-text-center">
              <label className="uk-form-label uk-margin-small-right" htmlFor="groupByCong">Impressão por Congregação?</label>
              <input className="uk-checkbox" 
                type="checkbox"    
                id="groupByCong"
                checked={groupByCong}
                disabled={isPriting}
                onChange={(e) => {
                  setGroupByCong(e.target.checked);
                }}/>
            </div>
            <div className="uk-width-1-4">              
              <button
                className="uk-button uk-button-primary uk-button-small uk-flex uk-flex-middle uk-margin-small-left"
                disabled={isPriting || total == 0}
                onClick={() => handlePrint()}
              >
                <span>Imprimir Lista de Presença</span>
                {isPriting ? (
                  <div
                    className="uk-margin-small-left"
                    uk-spinner="true; ratio:0.5"
                  ></div>
                ) : (
                  <FontAwesomeIcon
                    className="uk-margin-small-left"
                    icon={faPrint}
                  />
                )}
              </button>
            </div>                   
          </div>
          <div className="uk-width-1-1">
            <TablePagination
              columns={columns}
              fetchData={fetchMembers}
              refresh={refresh}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
