import Modal from "react-modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { displayDateTime, handleFocus, numberMask } from "../../../utils/masks";
import { getValidator } from "../../../utils/validatorsYup";
import { utils } from "../../../utils/utils";

import DialogConfirm from "../../../components/DialogConfirm";

interface Params {
  member: any;
  open: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

export default function MemberCredencialResponse(props: Params) {
  const { member, open, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [openConfirm, setConfirmeOpen] = useState<boolean>(false);

  Modal.setAppElement("#root");

  const schema = yup.object().shape({
    cardNumber: getValidator({isNumeric: true}).required('Nº Carteirinha é obrigatório'),
    note: yup.string()
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setModelOpen(open);
  }, [member, open]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    setConfirmeOpen(false);
    afterCloseModal();
  };

  const onSubmit = async (dataMember: any) => {
    try {
      const { data } = await api.post(`/members/${member.id}/credential/print`);

      const name = `${member?.name.toLocaleLowerCase().split(' ')[0]}-credencial-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item, "application/pdf", true);
      setConfirmeOpen(true);
    } catch (e: any) {
      e?.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleProcessCredential = async () => {
    await api.post(`/members/${member.id}/credential/response`, member);
    toast.success("Credencial entregue!", toastOptions);
    handleCloseModal();
    afterHandleSubmit();
  }

  const dialogClose = () => {
    setConfirmeOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            Processar Carteirinha
          </h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={handleCloseModal}
        />
        <form onSubmit={handleSubmit<any>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-2@s">
                <label className="uk-form-label" htmlFor="note">Nome</label>
                <input
                    id="name" 
                    name="name"
                    className="uk-input uk-form-small"
                    defaultValue={member?.name}
                    ref={register}
                    disabled={true}
                />
              </div>
              <div className="uk-width-1-2@s">
                <label className="uk-form-label" htmlFor="note">Função Eclesiástica</label>
                <input
                    id="ecclesiasticalFunction" 
                    name="ecclesiasticalFunction"
                    className="uk-input uk-form-small"
                    defaultValue={member?.ecclesiasticalFunction?.description}
                    ref={register}
                    disabled={true}
                />
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-width-1-2@s">
                  <div className="uk-form-controls">
                    <label className="uk-form-label" htmlFor="cardNumber">Nº Carteirinha</label>
                    <input type="text" 
                      id="cardNumber" 
                      name="cardNumber"
                      className="uk-input uk-form-small"
                      defaultValue={member?.cardNumber}
                      ref={register}
                      onFocus={handleFocus}
                      autoComplete={"off"}
                      placeholder="999999"
                      onChange={(e) => { e.target.value = numberMask(e.target.value) }}
                    />
                  </div>
                  <small className="uk-text-danger">{errors.cardNumber?.message}</small>
                </div>
                <div className="uk-width-1@s">
                  <label className="uk-form-label" htmlFor="note">Observações</label>
                  <textarea
                      id="note" 
                      name="note"
                      className="uk-textarea uk-form-small uk-text-uppercase" 
                      rows={4} 
                      style={{resize: 'none'}}
                      defaultValue={member?.note}
                      autoComplete={"off"}
                      ref={register}
                  />
                  <small className="uk-text-danger">{errors.note?.message}</small>
                </div>  
              </div>
              <div className="uk-width-1-2@s">
                <div className="uk-grid-small" uk-grid="true">
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-controls">
                      <label className="uk-form-label" htmlFor="reason">Motivo</label>
                      <input type="text" 
                        id="reason" 
                        name="reason"
                        disabled={true}
                        className="uk-input uk-form-small uk-text-bold"
                        defaultValue={member?.historic.reason}
                        ref={register}
                      />
                    </div>
                  </div>
                  <div className="uk-width-1-2@s">
                    <div className="uk-form-controls">
                      <label className="uk-form-label" htmlFor="requestedOn">Solicitado em</label>
                      <input type="text" 
                        id="requestedOn" 
                        name="requestedOn"
                        disabled={true}
                        className="uk-input uk-form-small"
                        defaultValue={displayDateTime(member?.historic.createdDate)}
                        ref={register}
                      />
                    </div>
                  </div>               
                </div>
                <div className="uk-width-1@s">
                  <div className="uk-form-controls">
                    <label className="uk-form-label" htmlFor="requestedBy">Solicitado por</label>
                    <input type="text" 
                      id="requestedBy" 
                      name="requestedBy"
                      disabled={true}
                      className="uk-input uk-form-small"
                      defaultValue={member?.historic.createdBy}
                      ref={register}
                    />
                  </div>
                </div>              
                <div className="uk-width-1@s">
                  <label className="uk-form-label" htmlFor="histNote">Observações da Solicitação</label>
                  <textarea
                      id="histNote" 
                      name="histNote"
                      className="uk-textarea uk-form-small uk-text-uppercase" 
                      rows={2} 
                      style={{resize: 'none'}}
                      defaultValue={member?.historic.note}
                      disabled={true}
                      ref={register}
                  />
                </div> 
              </div>
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={handleCloseModal}
                disabled={formState.isSubmitting}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Emitir Credencial
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <DialogConfirm
        open={openConfirm}
        text={[
            `A Credencial do membro ${member?.name} foi impressa corretamente?`,
            `Se sim clique em [Confirmar], se não clique em [Cancelar] e tente novamente.`
        ]}
        title="Credencial Impressa"
        handleConfirmation={handleProcessCredential}
        afterClose={dialogClose}                
      ></DialogConfirm>
    </>
  );
}
