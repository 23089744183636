import { forwardRef, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";

interface Params {
    columns: any[];
    fetchData: any;
    showPlusButton?: boolean;
    clickPlusButton?: any;
    refresh?: boolean;
    pagingOpt?: number[];
    messageEmpty?: string;
}

const TablePagination = forwardRef((props: Params, ref) => {
    const { columns, fetchData, showPlusButton, clickPlusButton, refresh, pagingOpt, messageEmpty } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const limit = useRef<number>();

    useEffect(() => {
        handlePageChange(1);
    }, [refresh]);
    
    if (pagingOpt) {
        limit.current = pagingOpt[0];
    }else{
        limit.current = 10;
    }

    const handlePageChange = async (page: number) => {
        setLoading(true);
        const response = await fetchData(page, limit.current);
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        const response = await fetchData(page, newPerPage);
        setData(response.data.data);
        limit.current = newPerPage; 
        setLoading(false);
    };    

    return (
        <DataTable
            columns={columns}
            data={data}
            highlightOnHover={true}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            responsive
            pagination
            paginationComponentOptions={
                {
                    rowsPerPageText: 'Registros por página:',
                    rangeSeparatorText: 'de',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Todos'
                }
            }
            progressPending={loading}
            progressComponent={
                <div uk-spinner="true; ratio:2">Carregando...</div>
            }
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={pagingOpt ? pagingOpt : [10, 25, 50, 100, 500]}
            paginationPerPage={limit.current}
            noDataComponent={            
                <div className='uk-flex uk-flex-middle uk-height-small'>
                    {
                        messageEmpty ? messageEmpty : 'Sem registros para serem exibidos!'
                    }
                    <span className="uk-margin-left" uk-icon="icon: happy; ratio: 2"></span>
                </div>
            }
        />
    );
})

export default TablePagination;