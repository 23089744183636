import { useEffect, useState } from "react";
import toastOptions from "../../../utils/toastOptions";
import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import { useHistory } from "react-router";

import "./event-running.css";
import {
  CPFIsValid,
  cpfMask,
  numberMask,
} from "../../../utils/masks";
import Countdown from "react-countdown";
import DialogConfirm from "../../../components/DialogConfirm";
import { authenticationService } from "../../../services/auth.service";
import { Gender } from "../../../models/enums/Gender";
import IPersonPresenceDTO from "../../../models/IPersonPresenceDTO";
import IPresenceOpenDTO from "../../../models/IPresenceOpenDTO";
import IPersonPresence from "../../../models/IPersonPresence";

export default function EventRunning() {
  const history = useHistory();
  const [presence, setPresenceList] = useState<IPresenceOpenDTO>();
  const [entry, setEntry] = useState("");
  const [personPresence, setPersonPresence] = useState<IPersonPresence>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [refreshing, setRefresh] = useState<boolean>(false);
  const [closing, setClosing] = useState<boolean>(false);

  var input;

  const toastOptionsPage: ToastOptions = {
    pauseOnFocusLoss: false,
  };

  useEffect(() => {
    const init = async () => {
      const { data } = await api.get("/events/1/presence/open");

      toastOptionsPage.onClose = () => {
        history.push("/eventos");
      };

      if (!data.success) {
        toast.error("Problemas ao carregar Lista", toastOptionsPage);
        return;
      }

      if (!data.item) {
        toast.error("Nenhuma Lista em ABERTO foi localizado", toastOptionsPage);
        return;
      }

      setPresenceList(data.item);
      setEntry("");
      setPersonPresence(undefined);
      setRefresh(false);

      const ele = document.getElementById("navbar-main");
      if (ele) {
        ele.className += " uk-hidden";
      }
    };

    init();
  }, []);

  const handleEntry = async (ev: any) => {
    if (ev.charCode == 13) {
      if (!entry) return;

      setRefresh(true);
      const param = {
        idPresenceList: presence?.presenceList.id,
      } as IPersonPresenceDTO;

      if (entry.length > 6) {
        if (!CPFIsValid(entry)) {
          toast.warn("CPF Inválido", toastOptionsPage);
          ev.target.select();
          setRefresh(false);
          return;
        }

        param.document = entry;
      }

      if (!param.document) {
        param.cardNumber = +entry;
      }

      try {
        const { data } = await api.post("/events/:idEvent/entry", param);

        if (!data.success) {
          data.messages?.forEach((m: string) => {
            toast.warn(m, toastOptionsPage);
          });
          ev.target.value = "";
          setEntry("");
          ev.focus();
          setRefresh(false);
          return;
        }

        setPersonPresence(data.item);

        ev.target.value = "";
        setEntry("");
        setRefresh(false);

        ev.focus();
      } catch (e: any) {
        e.messages?.forEach((m: string) => {
          toast.error(m, toastOptionsPage);
        });

        setRefresh(false);
      }
    }
  };

  const handleEntryChange = (e: any) => {
    e.target.value =
      e.target.value.length > 6
        ? cpfMask(e.target.value)
        : numberMask(e.target.value);
    setEntry(e.target.value);
  };

  const rendererCountdown = (renderOptions: any) => {
    if (renderOptions.completed) {
      setPersonPresence(undefined);
      return <span></span>;
    } else {
      return (
        <span>
          Seus dados serão ocultados em {renderOptions.seconds} segundos{" "}
        </span>
      );
    }
  };

  const handleCloseEvent = async (confirmationCode: string) => {
    if (!confirmationCode) {
      toast.warn("Informe a senha", toastOptions);
      return;
    }

    setConfirm(false);
    setRefresh(true);
    setClosing(true);

    try {
      const { data } = await api.post(`/events/presence/${presence?.presenceList.id}/close`, {
        confirmationCode: confirmationCode,
      });

      if (!data.success) {
        data.messages?.forEach((m: string) => {
          if (m.indexOf("FINALIZADO") > 0) {
            toastOptions.onClose = () => {
              setRefresh(false);
              setClosing(false);
              history.push("/");
            };
          }
          toast.error(m, toastOptions);
        });
        return;
      }

      toastOptionsPage.onClose = () => {
        setRefresh(false);
        setClosing(false);
        history.push("/eventos");
      };

      setPersonPresence(undefined);
      toast.success("Lista sendo fechada...", toastOptionsPage);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });

      setRefresh(false);
      setClosing(false);
    }
  };

  const dialogClose = () => {
    setConfirm(false);
  };

  return (
    <>
      <DialogConfirm
        open={confirm}
        text={[""]}
        title="Fechar Lista"
        confirmTextLabel="Confirme a senha para fechar o Lista"
        handleConfirmation={handleCloseEvent}
        afterClose={dialogClose}
      ></DialogConfirm>
      <div className="uk-section" id="event-list-running">
        {!presence ? (
          <div className="uk-flex uk-flex-center">
            <div uk-spinner="true; ratio:2">Carregando...</div>
          </div>
        ) : (
          <div>
            {authenticationService.isAdmin() && (
              <button
                id="btn-close-meeting"
                className="uk-button uk-button-primary uk-margin-bottom uk-button-small uk-flex uk-flex-middle"
                type="button"
                disabled={confirm || closing}
                onClick={() => setConfirm(true)}
              >
                {closing ? (
                  <div uk-spinner="true; ratio:0.5"></div>
                ) : (
                  <span
                    className="uk-margin-small-right"
                    uk-icon={`icon: close;`}
                  ></span>
                )}
                Fechar Lista
              </button>
            )}

            <div className="uk-card uk-card-body uk-card-success uk-card-hover">
              <div className="uk-inline-clip uk-transition-toggle">
                <h1>Informe seu CPF ou Escaneie o Código de Barras</h1>
                <div className="uk-flex">
                  <input
                    type="text"
                    id="entry"
                    name="entry"
                    placeholder="CPF ou Código de Barras"
                    className="uk-input uk-form-large"
                    autoComplete={"off"}
                    onKeyPress={handleEntry}
                    onChange={handleEntryChange}
                    autoFocus={true}
                    defaultValue={entry}
                    disabled={refreshing}
                    ref={(ref) => {
                      input = ref;
                      input?.focus();
                    }}
                  />
                  {refreshing && <div uk-spinner="true; ratio: 2"></div>}
                </div>
              </div>
            </div>

            {personPresence && (
              <div className="uk-card uk-card-body uk-card-success uk-card-hover">
                <div className="uk-grid-small" uk-grid="true">
                  <div className="uk-width-1-1@s">
                    <h3>
                      Bem-vind{personPresence.person.gender == Gender.FEMALE ? "a" : "o"}{" "}
                      {personPresence.person.name}
                    </h3>
                  </div>                  
                  <div className="uk-width-1-1@s">
                    <label className="uk-text-italic uk-text-small">
                      <Countdown
                        date={Date.now() + 5000}
                        renderer={(r) => rendererCountdown(r)}
                      />
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
