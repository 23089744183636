import Modal from "react-modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { TypeHistory } from "../../../models/enums/TypeHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { dateMask, handleFocus } from "../../../utils/masks";
import { getValidator } from "../../../utils/validatorsYup";
import moment from "moment";
import Param from "../../../models/Param";

interface Params {
  id: number;
  ecclesiasticalFunction?: Param;
  open: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

export default function MemberCredencialRequest(props: Params) {
  const { id, ecclesiasticalFunction, open, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [isDue, setDue] = useState<boolean>(false);
  const [isChangeFunction, setChangeFunction] = useState<boolean>(false);
  const [functions, setFunctions] = useState<Param[]>([]);
  const [reasonSelected, setReason] = useState<string>();

  Modal.setAppElement("#root");

  const schema = yup.object().shape({
    reason: yup.string().required('Motivo é obrigatório'),
    note: yup.string().when('reason', (reason: TypeHistory, schema: any) => {
      return [TypeHistory.FIRST_CREDENTIAL, TypeHistory.CREDENCIAL_OTHER, TypeHistory.CREDENTIAL_AMEND].indexOf(reason) >= 0 ?
        yup.string().required(`Observação é obrigatória quando motivo é ${reason}`) : yup.string()
    }),
    dueDate: getValidator({isDate: true}),
    newFunction: yup.string().when('reason', {
      is: TypeHistory.CREDENTIAL_CHANGE_FUNCTION,
        then: yup.string().required(`Nova função é obrigatória`),
      })
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    errors,
    formState
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setModelOpen(open);

    const init = async()=> {
      const dataFunctions = await api.get(`params/ecclesiasticals`);
      setFunctions(dataFunctions.data);
    }

    init();
  }, [id, open]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = (reason?: TypeHistory) => {
    setModelOpen(false);
    afterCloseModal(reason);
  };

  const onSubmit = async (data: any) => {
    try {
      if (isDue) {
        data.additionalInfo = `Vencida em ${moment(data.dueDate).format('DD/MM/yyyy')}`;
        delete data.dueDate;
      }

      if (isChangeFunction) {
        data.additionalInfo = data.newFunction;
        delete data.newFunction;
      }

      await api.post(`/members/${id}/new-credential`, data);
      toast.success("Carteirinha solicitada!", toastOptions);
      handleCloseModal(data.reason);
      afterHandleSubmit();
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleType = (type: string) => {
    setReason(type);
    setDue(type == TypeHistory.CREDENTIAL_DUE);
    setChangeFunction(type == TypeHistory.CREDENTIAL_CHANGE_FUNCTION);
    clearErrors();
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            Nova Carteirinha
          </h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={()=> handleCloseModal()}
        />
        <form onSubmit={handleSubmit<any>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-3@s">
                <div className="uk-form-controls">
                  <label className="uk-form-label" htmlFor="reason">Motivo</label>
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                      <select className="uk-form-small"
                          id="reason" 
                          name="reason"
                          defaultValue={""}
                          ref={register}
                          onChange={(el) => handleType(el.target.value)}
                      >
                          <option value="" key={`reason0`}>Selecione...</option>
                          <option value={TypeHistory.FIRST_CREDENTIAL} key={`reason1`}>{TypeHistory.FIRST_CREDENTIAL}</option>
                          <option value={TypeHistory.CREDENTIAL_BAPTISM} key={`reason2`}>{TypeHistory.CREDENTIAL_BAPTISM}</option>
                          <option value={TypeHistory.CREDENTIAL_LOST} key={`reason3`}>{TypeHistory.CREDENTIAL_LOST}</option>
                          <option value={TypeHistory.CREDENTIAL_DUE} key={`reason4`}>{TypeHistory.CREDENTIAL_DUE}</option>
                          <option value={TypeHistory.CREDENTIAL_CHANGE_FUNCTION} key={`reason5`}>{TypeHistory.CREDENTIAL_CHANGE_FUNCTION}</option>
                          <option value={TypeHistory.CREDENTIAL_AMEND} key={`reason6`}>{TypeHistory.CREDENTIAL_AMEND}</option>
                          <option value={TypeHistory.CREDENTIAL_RECEPTION} key={`reason7`}>{TypeHistory.CREDENTIAL_RECEPTION}</option>
                          <option value={TypeHistory.CREDENCIAL_OTHER} key={`reason8`}>{TypeHistory.CREDENCIAL_OTHER}</option>
                      </select>
                      <button 
                          className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                          style={{textTransform: 'none'}} 
                          type="button" 
                          tabIndex={-1}
                      >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                      </button>
                  </div>
                </div>
                <small className="uk-text-danger">{errors.reason?.message}</small>
              </div>
              {
                isDue && (
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="dueDate">Data Vencimento</label>
                    <div className="uk-form-controls">
                      <input type="type" 
                          id="dueDate" 
                          name="dueDate" 
                          placeholder="99/99/9999" 
                          className="uk-input uk-form-small"
                          defaultValue={""}
                          autoComplete={"off"}
                          onFocus={handleFocus}
                          ref={register}
                          onChange={(e) => { e.target.value = dateMask(e.target.value) }}
                      />
                      <small className="uk-text-danger">{errors.dueDate?.message}</small>
                    </div>
                  </div>
                )
              }
              {
                isChangeFunction && [
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="currentFunction">Função Eclesiástica Atual</label>
                    <div className="uk-form-controls">
                      <input type="type" 
                          id="currentFunction" 
                          name="currentFunction" 
                          className="uk-input uk-form-small"
                          defaultValue={ecclesiasticalFunction?.description}
                          autoComplete={"off"}
                          disabled={true}
                      />
                    </div>
                  </div>,
                  <div className="uk-width-1-3@s">
                    <label className="uk-form-label" htmlFor="newFunction">Nova Função Eclesiástica</label>
                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                      <select className="uk-form-small"
                        id="newFunction" 
                        name="newFunction"
                        defaultValue={""}
                        ref={register}
                      >
                        <option value="">Selecione...</option>
                        {
                          functions.map(f => {
                            if (f.id == ecclesiasticalFunction?.id) return;
                            return <option value={f.id} key={`ecclessiastical${f.id}`}>{f.description}</option>
                          })
                        }
                      </select>
                      <button 
                        className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                        style={{textTransform: 'none'}} 
                        type="button" 
                        tabIndex={-1}
                      >
                        <span></span>
                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                    <small className="uk-text-danger">{errors.newFunction?.message}</small>
                  </div>
                ]
              }
              {
                reasonSelected == TypeHistory.FIRST_CREDENTIAL && (
                  <div className="uk-width-1 uk-text-warning">
                    <small>
                      Usada para membros da AD Belém que não possuem credencial (NÃO usar opção para membros recebidos de outros ministérios)
                      <br/>
                      Use {TypeHistory.CREDENTIAL_RECEPTION} se está sendo recebido de outro ministério
                    </small>
                  </div>
                )
              }
              <div className="uk-width-1@s">
                <label className="uk-form-label" htmlFor="note">Observações</label>
                <textarea
                    id="note" 
                    name="note"
                    className="uk-textarea uk-form-small uk-text-uppercase" 
                    rows={4} 
                    style={{resize: 'none'}}
                    defaultValue={""}
                    autoComplete={"off"}
                    ref={register}
                />
                <small className="uk-text-danger">{errors.note?.message}</small>
              </div>           
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={() => handleCloseModal()}
                disabled={formState.isSubmitting}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Solicitar
                <FontAwesomeIcon className="uk-margin-left" icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}