import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";

import "react-toastify/dist/ReactToastify.css";
import { displayDateTime, numberMask } from "../../../utils/masks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { utils } from "../../../utils/utils";
import { toast } from "react-toastify";
import toastOptions from "../../../utils/toastOptions";
import DialogConfirm from "../../../components/DialogConfirm";
import { authenticationService } from "../../../services/auth.service";

export default function EventPrintList() {
  const [refresh, doRefresh] = useState(false);
  const [reprint, setReprint] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [termSearch, setTermSearch] = useState<string>("");
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [openConfirm, setConfirmeOpen] = useState<boolean>(false);
  const [numberSkip, setNumberSkip] = useState<number>(0);

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: any) => <span>{row.name}</span>,
    },
    {
      name: "Impresso em",
      sortable: true,
      cell: (row: any) => <span>{displayDateTime(row.printedDate)}</span>
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: any, index: number) => (
        <div className="uk-flex">
          <Link
            to={`/eventos/pessoas/${row.id}`}
            uk-tooltip="title: Visualizar"
            className="uk-margin-small-right"
            target={"blank"}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Link>     
        </div>
      ),
    }
  ];

  useEffect(() => {}, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    doRefresh(!refresh);
  }, [delayedSearchTerm]);

  const fetchPrints = async (page: number, limit: number) => {
    try {
      const response = await api.get(`/events/people/print`, { params: buildParams(page, limit) });

      setTotal(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleOpen = async(selected: boolean) => {
    setReprint(selected);
    doRefresh(!refresh);
  }

  const handlePrint = async () => {
    setIsRunning(true);
    try {
      let params = buildParams(0, 0);

      if (numberSkip > 0) {
        params = { ...params, numberSkip: numberSkip };
      }

      const { data } = await api.post("/events/1/people/credential", { params: params });

      const name = `credenciais-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
      setConfirmeOpen(true);      
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  };

  const buildParams = (page: number, limit: number) => {
    let params = {
      page: page,
      limit: limit,
      open: reprint
    } as any;

    if (termSearch) {
      params = { ...params, name: termSearch?.toUpperCase() };
    }

    return params;
  }

  const handleSearch = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleProcessCredential = async () => {
    setIsRunning(true);
    try {
      const { data } = await api.post("/events/1/people/credential/confirm", { params: buildParams(0, 0) });

      if (data.success) {
        toast.success("Credenciais impressas!", toastOptions);
        doRefresh(!refresh);
      } else {
        toast.warn("Credenciais não confirmadas!", toastOptions);
      }

      setConfirmeOpen(false);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  }

  return (
    <div id="page-event-print-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Impressões
              </h3>
              <h5 className="uk-text-meta uk-text-small uk-margin-remove-top">
                Apenas Inscrições pagas serão listadas
              </h5>  
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/eventos"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-grid-small uk-flex uk-flex-middle" uk-grid="true">
            <div className="uk-width-2-3@m">
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1-3@s uk-flex uk-flex-middle">
                  <label className="uk-margin-small-right">
                    Total: {total}
                  </label>
                  <button
                    type="button"
                    className="uk-button uk-button-primary uk-button-small uk-flex uk-flex-middle uk-margin-small-right"
                    disabled={isRunning || total == 0 || authenticationService.isReadOnly()}
                    onClick={() => handlePrint()}
                  >
                    Imprimir
                    {isRunning ? (
                      <div
                        className="uk-margin-small-left"
                        uk-spinner="true; ratio:0.5"
                      ></div>
                    ) : (
                      <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} />
                    )}
                  </button>
                </div>
                <div className="uk-width-1-5@s uk-flex uk-flex-middle">
                  <label className="uk-form-label uk-margin-small-right" htmlFor="open">Reimprimir?</label>
                  <input className="uk-checkbox" 
                    type="checkbox"    
                    id="open"
                    checked={reprint}
                    onChange={(e) => {
                      handleOpen(e.target.checked);
                    }}/>
                </div>
                <div className="uk-width-1-4@s uk-flex uk-flex-middle">
                  <label className="uk-form-label uk-margin-small-right" htmlFor="numberSkip">Pular</label>
                  <input
                    type="text"
                    id="numberSkip"
                    name="numberSkip"
                    placeholder="Pular # Etiquetas"
                    className="uk-input uk-form-small"
                    autoComplete={"off"}
                    onChange={(e) => {
                      setNumberSkip(+numberMask(e.target.value));
                    }}
                  />
                </div>
              </div>
            </div>            
            <div className="uk-width-1-3@m uk-flex uk-flex-middle">
              <div className="uk-search uk-search-default uk-width-1-1@m">
                <span
                  className="uk-search-icon-flip"
                  uk-search-icon="true"
                ></span>
                <input
                  className="uk-search-input uk-form-small"
                  type="search"
                  placeholder="Pesquise por nome..."
                  style={{ textTransform: "uppercase" }}
                  onChange={(ev) => {
                    handleSearch(ev.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchPrints}
            refresh={refresh}
          />
          <DialogConfirm
            open={openConfirm}
            text={[
                `A impressão das credenciais foi correta?`,
                `Se sim clique em [Confirmar], se não clique em [Cancelar] e tente novamente.`
            ]}
            title="Impressão"
            handleConfirmation={handleProcessCredential}
            afterClose={() => {setConfirmeOpen(false);}}                
          ></DialogConfirm>
        </div>
      </div>
    </div>
  );
}
