import { faCalendar, faPencil, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DialogConfirm from "../../../components/DialogConfirm";
import TablePagination from "../../../components/TablePagination";
import ISchedule from "../../../models/ISchedule";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";
import { displayDate } from "../../../utils/masks";
import toastOptions from '../../../utils/toastOptions';
import ScheduleAddEdit from "../schedule-add-edit/ScheduleAddEdit";

export default function CalendarList() {
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [isAuxSec, setAuxSec] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [ixRunning, setIxRunning] = useState<number>(-1);
  const [pastEvents, setPastEvents] = useState<boolean>(false);
  const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
  const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
  var scheduleSelected = useRef(0);
  var ixScheduleSelected = useRef(-1);
  const initialYear = useRef<number>(2022);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);
  
  const columns = [
    {
      name: "Data",
      sortable: true,
      cell: (row: ISchedule) => (
        <div className={`${isToday(row) && 'uk-text-primary'}`}>
          <span>{displayDate(row.date.toString())} {row.hour}</span>
        </div>
      )
    },
    {
      name: "Evento",
      sortable: true,
      grow: 2,
      cell: (row: ISchedule) => (
        <div className={`${isToday(row) && 'uk-text-primary'}`}>
          <span>{row.description}</span>
        </div>
      )
    },
    {
      name: "Obs.",
      sortable: true,
      cell: (row: ISchedule) => (
        <div className={`${isToday(row) && 'uk-text-primary'}`}>
          <span>{row.note}</span>
        </div>
      )
    },    
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: ISchedule, index: number) => (
        <div className="uk-text-center">
          {            
              isReadOnly ? 
                <a href="#" uk-tooltip="title: Visualizar"
                  onClick={(ev) => { ev.preventDefault(); handleEdit(row.id ?? 0) }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </a>
              :
                ixRunning == index ? <div uk-spinner="true; ratio:0.5"></div> :
                  <>
                    <a href="#" uk-tooltip="title: Editar"
                      className="uk-margin-right"
                      onClick={(ev) => { ev.preventDefault(); handleEdit(row.id ?? 0) }}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </a>
                    <a href="#" 
                      uk-tooltip="title: Remover"
                      className="uk-margin-right"
                      onClick={(ev) => { ev.preventDefault(); handleRemove(row, index) }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </>
          }
        </div>
      ),
    },
  ];

  const isToday = (row: ISchedule)=> {
    const date = moment(row?.date?.toString());
    const today = moment(new Date());
    return (date.date() == today.date() && date.month() == today.month()); 
  };

  const handleRemove = async (row: ISchedule, ix: number) => {
    setDialogDeleteText([`Deseja realmente excluir ${row.description}?`]);
    scheduleSelected.current = row.id;
    setIsDialogDelete(true);
    ixScheduleSelected.current = ix;
  };

  const dialogClose = () => {
    setIsDialogDelete(false);
    setIxRunning(-1);
  }

  const dialogConfirm = async (confirmation: any) => {     
    setIsDialogDelete(false);   
    try {
      setIxRunning(ixScheduleSelected.current);

      await api.delete(`schedules/${scheduleSelected.current}`);   

      setRefresh(!refresh);
      toast.success("Evento removido com sucesso!", toastOptions); 
    } catch (e: any) {
        e.messages?.forEach((m: string) => {
            toast.error(m, toastOptions);
        });
    } finally {
      setIxRunning(-1);
      scheduleSelected.current = 0;
      ixScheduleSelected.current = -1;
    }
  }

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    setAuxSec(authenticationService.isAuxSec());
    init();
  }, []);

  const init = async () => {
    handleYears();
  };

  const fetchSchedule = async (page: number, limit: number) => {
    try {
      const response = await api.get(`schedules`, {
        params: {
          page: page,
          limit: limit,
          pastEvents: pastEvents,
          year: currentYear
        }
      });

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleAdd = ()=> {
    setAdd(true);
  }

  const handleEdit = async (id: number) => {
    setId(id);        
  }

  const afterHandleSubmit = () => {
    setAdd(false); 
    setId(0);
    setRefresh(!refresh); 
  }

  const afterCloseModal = () => {
    setAdd(false);
    setId(0);
  }

  const handleSearch = (checked: boolean) => {
    setPastEvents(checked);
    setRefresh(!refresh);
  }

  const handleSearchYear = (newYear: number) => {
    setCurrentYear(newYear);
    setRefresh(!refresh);
  }

  const handleYears = () => {
    setYears([]);

    let initYear = currentYear;

    if (moment().month() == 11) {
      initYear++;
    }

    for (let i = initYear; i >= initialYear.current; i--) {
        setYears(array => [...array, i]);
    }
  }

  return (
    <div
      className="uk-card uk-card-default uk-card-small uk-card-hover"
      id="schedule-list"
    >
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
          <div className="uk-width-expand">
            <h3 className="uk-card-title uk-margin-remove-bottom">Agenda</h3>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        <div className="uk-grid-small uk-margin-top" uk-grid="true">
          <div className="uk-search uk-search-default uk-width-1-1 uk-flex uk-flex-middle" uk-grid="true">
            <div className="uk-width-1-4@s uk-flex uk-flex-middle">
              <label>Ver eventos passados?</label>
              <label>
                <input className="uk-checkbox uk-margin-left" 
                  type="checkbox"    
                  id="previousSchedule"
                  onChange={(e) => {
                    handleSearch(e.target.checked);
                  }}/>
              </label>
            </div>
            <div className="uk-width-1-4@s uk-flex uk-flex-middle">
              <label htmlFor="year">Ano</label>
              <div className="uk-width-1-2@s uk-margin-left">
                <div className="uk-form-controls">
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                    <select className="uk-form-small"
                      id="year" 
                      placeholder="Ano"
                      name="year"
                      value={currentYear}
                      onChange={(ev) => handleSearchYear(+(ev.target.value))}
                    >       
                      {
                        years?.map(y => {
                            return <option key={`ano${y}`} value={`${y}`}>{y}</option>
                        })
                      }  
                    </select>
                    <button 
                      className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                      style={{textTransform: 'none'}} 
                      type="button" 
                      tabIndex={-1}
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-grid-small uk-width-3-4@m uk-flex" uk-grid="true">
            <div className="uk-width-1-3@m">
              <label>Próximos Eventos</label>
            </div>
            <div className="uk-width-1-3@m">
              <div className="uk-inline-clip uk-transition-toggle">
                <div className="uk-transition-scale-up uk-transition-opaque">
                  <Link 
                      to="/calendario"
                      className="uk-button uk-button-default uk-button-success uk-button-small">
                      Ver Calendário <FontAwesomeIcon icon={faCalendar} size='xl' />
                  </Link>   
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@m">
              {
                !isReadOnly && (
                  <div className="uk-inline-clip uk-transition-toggle">
                    <div className="uk-transition-scale-up uk-transition-opaque">
                      <button 
                        onClick={()=> handleAdd()}
                        className="uk-button uk-button-primary uk-button-small"
                      >
                        Adicionar <FontAwesomeIcon icon={faPlus} size='xl' />
                      </button>   
                    </div>
                  </div>
                )
              }            
            </div>         
          </div>
        </div>
        <TablePagination
          columns={columns}
          fetchData={fetchSchedule}
          refresh={refresh}
        />
        <ScheduleAddEdit 
          add={add} 
          id={id} 
          docId={id}
          afterHandleSubmit={afterHandleSubmit}
          afterCloseModal={afterCloseModal}
        />
        <DialogConfirm 
          open={isDialogDelete} 
          text={dialogDeleteText} 
          afterClose={dialogClose}
          handleConfirmation={(confirmationCode) => dialogConfirm(confirmationCode)}
        />
      </div>
    </div>
  );
}
