import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Typography } from '@material-ui/core';

interface Params {
    open: boolean;
    text: string[];
    title?: string;
    afterClose?: any;
    confirmTextLabel?: string; 
    handleConfirmation(confirmation: any): any;   
}

export default function DialogConfirm(props: Params) {
    const { open, text, title, afterClose, confirmTextLabel, handleConfirmation } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [textInput, setTextInput] = useState<string>("");

    useEffect(() => {
        setIsOpen(open);
        setTextInput('');
    }, [open]);

    const handleCancel = () => {
        setIsOpen(false);
        if (afterClose) {
            afterClose();
        }
    };

    const handleConfirm = () => {
        if (handleConfirmation) {
            handleConfirmation(textInput);
        }
    };

    const handleMaxWidthChange = () => {
        
    };

    const onClose = (event: any, reason: any) => {
        if (reason && reason === "backdropClick") return;

        handleCancel();
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <DialogTitle id="alert-dialog-title">{title ? title : 'Confirmação'}</DialogTitle>
                <DialogContent>
                    {
                        text.map((t, i) => {
                            return (
                                <Typography gutterBottom key={`modal${i}`}>
                                    {t}
                                </Typography>
                            ) 
                        })
                    }                    
                    {
                        confirmTextLabel && 
                            <form>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label={confirmTextLabel}
                                    type="password"
                                    fullWidth
                                    onChange={(e) => setTextInput(e.target.value)}
                                />
                            </form>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}