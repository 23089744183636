import Modal from "react-modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Param from "../../../models/Param";
import { EcclesiasticalFunction } from "../../../models/enums/EcclesiasticalFunction";
import states from "../../../assets/br.citys.json";
import { utils } from "../../../utils/utils";
import DialogConfirm from "../../../components/DialogConfirm";

interface Params {
  id: number;
  name: string;
  ecclesiasticalFunction?: Param;
  open: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

enum SignTypePerson {
  OTHER = "OUTRO"
}

export default function MemberLetterRecommendation(props: Params) {
  const {
    id,
    name,
    ecclesiasticalFunction,
    open,
    afterHandleSubmit,
    afterCloseModal,
  } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [cities, setCities] = useState<string[]>([]);
  const [isOtherPerson, setOtherPerson] = useState<boolean>(false);
  const [openConfirm, setConfirmeOpen] = useState<boolean>(false);
  const [letter, setLetter] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  Modal.setAppElement("#root");

  const schema = yup.object().shape({
    state: yup.string(),
    city: yup.string().when('state', {
      is: (value) => value != null && value != '',
      then: yup.string().required("Estado informado, Cidade obrigatória"),
    }),
    signPerson: yup.string().required("Quem irá assinar é obrigatório"),
    otherSignPerson: yup.string().when('signPerson', {
      is: SignTypePerson.OTHER,
      then: yup.string().required("Quem irá assinar é obrigatório"),
    }),
    note: yup.string()
  });

  const { register, handleSubmit, reset, clearErrors, errors, formState } =
    useForm({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    setModelOpen(open);

    const init = async () => {};

    init();
  }, [id, open]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    afterCloseModal();
  };

  const selectCities = (state: string) => {
    setCities([]);
    const citiesFromState = states
      .filter((s) => s.initial === state)
      .shift()?.cities;
    if (citiesFromState) {
      setCities(citiesFromState);
    }
  };

  const handleSignPerson = (signPersonSelected: string) => {
    setOtherPerson(signPersonSelected == SignTypePerson.OTHER);
    clearErrors();
  }

  const onSubmit = async (request: any) => {
    try {      
      request.id = id;

      setLetter(request);

      const { data } = await api.post(`members/${id}/letter-recommendation`, request);
    
      const nameLetter = `${name.toLocaleLowerCase().split(' ')[0]}-carta-recomendação-${new Date().getTime()}.pdf`;

      utils.printPDF(nameLetter, data.item.report);

      setLetter((prevLetter: any) => ({
        ...prevLetter,
        token: data.item.token
      }));

      setConfirmeOpen(true);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleProcessLetter = async () => {
    try {
      setIsLoading(true);
      dialogClose();
      await api.post(`/members/${id}/letter/response`, letter);
      toast.success("Carta de Recomendação impressa!", toastOptions);
      handleCloseModal();
      afterHandleSubmit();
    } finally {
      setIsLoading(false);
    }
  }

  const dialogClose = () => {
    setConfirmeOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Carta de Recomendação</h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={() => handleCloseModal()}
        />
        <form onSubmit={handleSubmit<any>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1@m">
                Emitir carta de recomendação {" - "}
                {ecclesiasticalFunction?.id != EcclesiasticalFunction.MEMBER &&
                  ecclesiasticalFunction?.description}{" "}
                {name}
              </div>
              <div className="uk-width-1@m">
                <div className="uk-form-controls">
                  <label className="uk-text-small uk-text-light uk-text-italic">
                    &nbsp;A assinatura do Pr. Tiago Fogaça constará na carta digital
                  </label>
                  <br />
                  <label className="uk-form-label" htmlFor="signPerson">Quem vai assinar como emissor?</label>
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                      <select className="uk-form-small"
                          id="signPerson" 
                          name="signPerson"
                          defaultValue={""}
                          ref={register}
                          onChange={(el) => handleSignPerson(el.target.value)}
                      >
                          <option value="" key={`signPerson0`}>Selecione...</option>
                          <option value="Pr. Samuel José Alves / 1º Secretário / CGADB 95.546" key={`signPerson1`}>
                            Pr. Samuel José Alves / 1º Secretário / CGADB 95.546
                          </option>
                          <option value="Pr. Frederico Martins Alves da Silva / 2º Secretário / CGADB 109.379" key={`signPerson2`}>
                            Pr. Frederico Martins Alves da Silva / 2º Secretário / CGADB 109.379
                          </option>
                          <option value="Coop. Guilherme A. de Oliveira Boccia / Secretário Adjunto" key={`signPerson3`}>
                            Coop. Guilherme Augusto de Oliveira Boccia / Secretário Adjunto
                          </option>
                          <option value={SignTypePerson.OTHER} key={`signPerson4`}>Outro</option>
                      </select>
                      <button 
                          className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                          style={{textTransform: 'none'}} 
                          type="button" 
                          tabIndex={-1}
                      >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                      </button>
                  </div>
                </div>
                <small className="uk-text-danger">{errors.signPerson?.message}</small>
              </div>
              {
                isOtherPerson && (
                  <div className="uk-width-1@m">
                    <div className="uk-form-controls">
                      <label className="uk-form-label" htmlFor="otherSignPerson">
                        Especifique o nome e a função. Ex: Pr. Beltrano / Dirigente
                      </label>
                      <div className="uk-form-controls">
                        <input type="text" 
                          id="otherSignPerson" 
                          name="otherSignPerson" 
                          className="uk-input uk-form-small"
                          autoComplete={"off"}
                          ref={register}
                        />
                      </div>
                    </div>
                    <small className="uk-text-danger">{errors.otherSignPerson?.message}</small>
                  </div>      
                )
              }
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="state">
                  Estado destino
                </label>
                <div
                  uk-form-custom="target: > * > span:first-child"
                  className="uk-width-1"
                >
                  <select
                    className="uk-form-small"
                    id="state"
                    name="state"
                    ref={register}
                    onChange={(el) => selectCities(el.target.value)}
                  >
                    <option value="" key="state0">
                      Selecione...
                    </option>
                    {states.map((f) => {
                      return (
                        <option value={f.initial} key={`state${f.initial}`}>
                          {f.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                    style={{ textTransform: "none" }}
                    type="button"
                    tabIndex={-1}
                  >
                    <span></span>
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
                <small className="uk-text-danger">
                  {errors.state?.message}
                </small>
              </div>
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="city">
                  Cidade destino
                </label>
                <div
                  uk-form-custom="target: > * > span:first-child"
                  className="uk-width-1"
                >
                  <select
                    className="uk-form-small"
                    id="city"
                    name="city"
                    ref={register}
                    disabled={cities.length === 0}
                  >
                    <option value="" key={`city0`}>
                      Selecione...
                    </option>
                    {cities.map((c) => {
                      return (<option value={c} key={c}>{c}</option>);
                    })}
                  </select>
                  <button
                    className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                    style={{ textTransform: "none" }}
                    type="button"
                    tabIndex={-1}
                  >
                    <span></span>
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
                <small className="uk-text-danger">
                  {errors.city?.message}
                </small>
              </div>              
              <div className="uk-width-1@s">
                <label className="uk-form-label" htmlFor="note">
                  Observações
                </label>                
                <textarea
                  id="note"
                  name="note"
                  className="uk-textarea uk-form-small"
                  rows={4}
                  style={{ resize: "none" }}
                  defaultValue={""}
                  autoComplete={"off"}
                  ref={register}
                />
                <label className="uk-text-small uk-text-light uk-text-italic">
                  Ao inserir algum valor aqui, ele virá imediamente após os dizeres iniciais: <br/> 
                  Observamos que [sua observação]
                </label>
                <small className="uk-text-danger">{errors.note?.message}</small>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={() => handleCloseModal()}
                disabled={formState.isSubmitting}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={(formState.isSubmitting || isLoading)}
                className="uk-button uk-button-primary uk-margin-small-left"
                type="submit"
              >
                {(formState.isSubmitting || isLoading) && <div uk-spinner="true"></div>}
                Imprimir
                <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} />
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <DialogConfirm
        open={openConfirm}
        text={[
            `A Carta de Recomendação do membro ${name} foi impressa corretamente?`,
            `Se sim clique em [Confirmar], se não clique em [Cancelar] e tente novamente.`
        ]}
        title="Carta de Recomendação Impressa"
        handleConfirmation={handleProcessLetter}
        afterClose={dialogClose}                
      ></DialogConfirm>
    </>
  );
}

