import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Meeting from "../../../models/Meeting";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticationService } from "../../../services/auth.service";
import api from "../../../services/api";
import toastOptions from "../../../utils/toastOptions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

interface MeetingParams {
    id: string;
}

export default function MeetingAdd() {
    const params = useParams<MeetingParams>();
    const history = useHistory();
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [isOficial, setIsOficial] = useState<boolean>(true);  
    
    const [meeting, setMeeting] = useState<Meeting>({
        title: `Reunião de Obreiros do Dia ${moment(new Date()).format('DD/MM/yyyy')}`,
        createdDate: new Date()
    });

    const schema = yup.object().shape({
        title: yup.string().required('Título obrigatório')
            .max(50, (val) => `Máximo ${val.max} caracteres`)
            .min(3, (val) => `Mínimo ${val.min} caracteres`),
        note: yup.string()
        .max(3000, (val) => `Máximo ${val.max} caracteres`)
    });

    useEffect(()=> {
        const init = async()=> {
            if (+(params.id) > 0) {
                await handleEdit(params.id);
            }

            setReadOnly(authenticationService.isReadOnly());
        }

        const handleEdit = async (id: string) => {
            try {
                const { data } = await api.get(`meetings/${id}`);

                setMeeting(data.item);
            } catch (e) {

            }
        }

        init();
    }, [params.id]);
    
    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: Meeting, e: any) => {
        try {
            data.isOficial = isOficial;

            if (!meeting?.id) {
                await api.post('meetings', data);
                toast.success('Reunião criada com sucesso!', toastOptions);
            } else {
                data.id = meeting.id;
                await api.put('meetings', data);
                toast.success('Reunião atualizada com sucesso!', toastOptions);
            }

            history.push("/reuniao/em-andamento");
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    return (
        (
            (params.id && !meeting) ? (
                <div className="uk-flex uk-flex-center">
                    <div uk-spinner="true; ratio:2">Carregando...</div>
                </div>
            ) :
            <>
                <div id="page-add-edit-member">
                    <form onSubmit={handleSubmit<Meeting>(onSubmit)} onReset={reset}>
                        <div className="uk-modal-body">
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-1@m">
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-1-2@m">
                                            <label className="uk-form-label" htmlFor="title">Título da Reunião</label>
                                            <textarea 
                                                id="title" 
                                                name="title" 
                                                placeholder="Título" 
                                                className="uk-textarea uk-form-small uk-text-uppercase"
                                                defaultValue={meeting?.title}
                                                style={{resize: 'none'}}
                                                rows={2}
                                                maxLength={100}
                                                ref={register} />
                                            <small className="uk-text-danger">{errors.title?.message}</small>
                                        </div>
                                        <div className="uk-width-1-4@m uk-flex uk-flex-middle">
                                            <label className="uk-form-label uk-margin-small-right" htmlFor="type">Oficial?</label>
                                            <input className="uk-checkbox uk-margin-left" 
                                                type="checkbox"
                                                defaultValue={`${true}`}
                                                checked={isOficial}
                                                ref={register}
                                                onChange={(e) => {
                                                    setIsOficial(e.target.checked);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="note">Observação</label>
                                    <textarea
                                        id="note" 
                                        name="note"
                                        className="uk-textarea uk-form-small uk-text-uppercase" 
                                        rows={6} 
                                        style={{resize: 'none'}}
                                        defaultValue={meeting?.note}
                                        ref={register}                                
                                    />
                                    <small className="uk-text-danger">{errors.note?.message}</small>
                                </div>
                            </div>
                        </div>
                        <div className="uk-modal-footer">
                            <div className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center" uk-grid="true">
                                <Link to="/inicio" className="uk-button uk-button-default uk-margin-left">
                                    Cancelar
                                </Link>
                                <button 
                                    id="btn-save"
                                    disabled={formState.isSubmitting || isReadOnly}
                                    className="uk-button uk-button-primary uk-margin-left" 
                                    type="submit">                                    
                                    {formState.isSubmitting && <div uk-spinner="true"></div>}
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>   
        )
    )
}