import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect, ChangeEvent } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import toastOptions from "../../../utils/toastOptions";
import {
  CEPMask,
  displayDateTime,
  filesAllowed,
  handleFocus,
  numberMask,
} from "../../../utils/masks";
import { Link, useHistory, useParams } from "react-router-dom";
import { getValidator } from "../../../utils/validatorsYup";

import states from "../../../assets/br.citys.json";
import addressTypes from "../../../assets/addresses.types.json";
import { authenticationService } from "../../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
} from "@fortawesome/free-solid-svg-icons";

import "../../shared/modal.css";
import "./congregation-add-edit.css";
import "../../shared/button.css";
import { StatusType } from "../../../models/enums/StatusType";
import Congregation from "../../../models/Congregation";

interface CongregationParams {
  id: string;
}

enum ImageType {
  CHURCH = 0,
  PASTORS = 1
}

export default function CongregationAddEdit() {
  const params = useParams<CongregationParams>();
  const history = useHistory();
  const [congregation, setCongregation] = useState<Congregation>();
  const [cities, setCities] = useState<string[]>([]);
  const [imageChurch, setImageChurch] = useState<File | string>();
  const [imagePastors, setImagePastors] = useState<File | string>();
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [canChange, setCanChange] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const schema = yup.object().shape({
    description: yup
      .string()
      .required("Nome obrigatório")
      .max(50, (val) => `Máximo ${val.max} caracteres`)
      .min(3, (val) => `Mínimo ${val.min} caracteres`),
    address: yup.string().required("Endereço obrigatório")
      .max(255, (val) => `Máximo ${val.max} caracteres`)
      .min(1, (val) => `Mínimo ${val.min} caracteres`),
    city: yup.string().required("Cidade obrigatório"),
    latitude: getValidator({ isNumeric: true }),
    longitude: getValidator({ isNumeric: true }),
    neighborhood: yup.string().required("Bairro obrigatório")
      .max(50, (val) => `Máximo ${val.max} caracteres`)
      .min(3, (val) => `Mínimo ${val.min} caracteres`),
    number: getValidator({ isNumeric: true }),
    state: yup.string().required("Estado obrigatório"),
    type: yup.string().required("Tipo Endereço obrigatório"),
    zipCode: getValidator({ isCEP: true }),
    addressLine2: yup.string()
      .max(100, (val) => `Máximo ${val.max} caracteres`),
      pastors: yup
      .string()
      .required("Dirigentes obrigatório")
      .max(100, (val) => `Máximo ${val.max} caracteres`)
      .min(3, (val) => `Mínimo ${val.min} caracteres`),
      contacts: yup
      .string()
      .required("Contatos obrigatório")
      .max(50, (val) => `Máximo ${val.max} caracteres`)
      .min(3, (val) => `Mínimo ${val.min} caracteres`),
    hoursInfo: yup.string()
      .required("Informações de Horários obrigatório")
      .max(100, (val) => `Máximo ${val.max} caracteres`),
      note: yup.string(),
      displayName: yup.string()
      .required("Nome Exibição obrigatório")
      .max(50, (val) => `Máximo ${val.max} caracteres`)
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const init = async () => {
      const adding = +params.id ? +params.id <= 0 : true;

      if (!adding) {
        handleEdit(params.id);
      }

      setDisabled(
        (authenticationService.isAuxSec() && !adding) ||
          authenticationService.isReadOnly()
      );

      setCanChange(authenticationService.canChange());
    };

    init();
  }, [params.id]);

  const handleEdit = async (id: string) => {
    try {
      const { data } = await api.get(`congregations/${id}`);

      setCongregation(data.item);
      setValue("state", data.item.state);
      selectCities(data.item.state);
      setValue("city", data.item.city);
      setImageChurch(data.item.photo);
      setImagePastors(data.item.pastorsPhoto);

    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.warning(m, toastOptions);
      });
    }
  };

  const onSubmit = async (data: Congregation) => {
    try {
      const formData = new FormData();

      data.id = congregation?.id;
      
      if (imageChurch && congregation?.photo != imageChurch) {
        formData.append("images", imageChurch);
        data.hasPhoto = true;
      }
      
      if (imagePastors && congregation?.pastorsPhoto != imagePastors) {
        formData.append("images", imagePastors);
        data.hasPastorsPhoto = true;
      }

      formData.append("data", JSON.stringify(data));

      if (!congregation?.id) {
        const result = await api.post("congregations", formData);
        toast.success("Congregação cadastrada com sucesso!", toastOptions);

        history.replace(`/congregations/${result.data.item.id}`);
      } else {
        const { data: result } = await api.put("congregations", formData);
        toast.success("Congregação atualizada com sucesso!", toastOptions);

        const m = Object.assign(congregation, {});
        result.item.item.id = 0;
        setCongregation(result.item.item);
        result.item.item.id = m.id;
        setCongregation(result.item.item);
      }

      setRefresh(!refresh);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const selectCities = (state: string) => {
    const citiesFromState = states
      .filter((s) => s.initial === state)
      .shift()?.cities;
    if (citiesFromState) {
      setCities(citiesFromState);
    }
  };

  const handleSelectImage = (event: ChangeEvent<HTMLInputElement>, type: ImageType) => {
    if (!event.target.files) return;

    const img = event.target.files[0];

    if (!filesAllowed(img)) {
      toast.warn("Permitido apenas imagens", toastOptions);
      return;
    }

    if (type == ImageType.CHURCH) {
      setImageChurch(img);
    } else {
      setImagePastors(img);
    }
  };

  const handleCEP = (value: string) => {
    const cep = value;

    if (cep.length == 9) {
      api.get(`https://viacep.com.br/ws/${cep}/json/`).then((resp: any) => {
        const { data } = resp;
        if (!data.erro) {
          const logradouro = data.logradouro.split(' ');
          const addressType = logradouro.shift(); //Remove first word

          setValue("neighborhood", data.bairro);
          setValue("type", addressType);
          setValue("address", logradouro.join(' '));
          setValue("state", data.uf);
          selectCities(data.uf);
          setValue("city", data.localidade);
        }

        if (data.logradouro) {
          document.getElementById("number")?.focus();
        } else {
          document.getElementById("address")?.focus();
        }
      });
    }

    setValue("zipCode", cep);

    return CEPMask(value);
  };

  return (params.id && !congregation) ? (
    <div className="uk-flex uk-flex-center">
      <div uk-spinner="true; ratio:2">Carregando...</div>
    </div>
  ) : (
    <div id="page-add-edit-congregation">
      <form
        onSubmit={handleSubmit<Congregation>(onSubmit)}
        onReset={reset}
      >
        <div className="uk-modal-body">
          <div className="uk-grid-small" uk-grid="true">
            <div className="uk-width-3-4@s">
              <div className="uk-grid-small" uk-grid="true">                
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="description">
                    Nome
                  </label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Nome"
                    className="uk-input uk-form-small"
                    defaultValue={congregation?.description}
                    autoComplete={"off"}
                    onFocus={handleFocus}
                    ref={register}
                  />
                  <small className="uk-text-danger">{errors.description?.message}</small>
                </div>
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label" htmlFor="displayName">
                    Nome Exibição (Site)
                  </label>
                  <input
                    type="text"
                    id="displayName"
                    name="displayName"
                    placeholder="Nome Exibição"
                    className="uk-input uk-form-small"
                    defaultValue={congregation?.displayName}
                    autoComplete={"off"}
                    onFocus={handleFocus}
                    ref={register}
                  />
                  <small className="uk-text-danger">{errors.displayName?.message}</small>
                </div>                              
              </div>
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1-1@s">
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-1-4@s">
                      <label className="uk-form-label" htmlFor="zipCode">
                        CEP
                      </label>
                      <input
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        placeholder="00000-000"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.zipCode}
                        autoComplete={"off"}
                        ref={register}
                        onFocus={handleFocus}
                        onChange={(e) => {
                          e.target.value = handleCEP(e.target.value);
                        }}
                      />
                      <small className="uk-text-danger">
                        {errors.zipCode?.message}
                      </small>
                    </div>                    
                    <div className="uk-width-1-4@m">
                      <label className="uk-form-label" htmlFor="stateBirth">
                        Tipo
                      </label>
                      <div
                        uk-form-custom="target: > * > span:first-child"
                        className="uk-width-1"
                      >
                        <select
                          className="uk-form-small"
                          id="type"
                          name="type"
                          defaultValue={congregation?.type}
                          ref={register}                         
                        >
                          <option value="" key="type0">
                            Selecione...
                          </option>
                          {addressTypes.map((t) => {
                            return (
                              <option value={t} key={`type${t}`}>
                                {t}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                          style={{ textTransform: "none" }}
                          type="button"
                          tabIndex={-1}
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                      <small className="uk-text-danger">
                        {errors.type?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-2@s">
                      <label className="uk-form-label" htmlFor="address">
                        Endereço
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Endereço"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.address}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.address?.message}
                      </small>
                    </div>                    
                  </div>
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-1-4@s">
                      <label className="uk-form-label" htmlFor="number">
                        Número
                      </label>
                      <input
                        type="text"
                        id="number"
                        name="number"
                        placeholder="00000"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.number}
                        ref={register}
                        autoComplete={"off"}
                        onFocus={handleFocus}
                        onChange={(e) => {
                          e.target.value = numberMask(e.target.value);
                        }}
                      />
                      <small className="uk-text-danger">
                        {errors.number?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-2@m">
                      <label className="uk-form-label" htmlFor="neighborhood">
                        Bairro
                      </label>
                      <input
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        placeholder="Bairro"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.neighborhood}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.neighborhood?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-4@m">
                      <label className="uk-form-label" htmlFor="state">
                        Estado
                      </label>
                      <div
                        uk-form-custom="target: > * > span:first-child"
                        className="uk-width-1"
                      >
                        <select
                          className="uk-form-small"
                          id="state"
                          name="state"
                          defaultValue={congregation?.state}
                          ref={register}
                          disabled={isDisabled}
                          onChange={(el) => selectCities(el.target.value)}
                        >
                          <option value="" key="state0">
                            Selecione...
                          </option>
                          {states.map((f) => {
                            return (
                              <option value={f.initial} key={`state${f.initial}`}>
                                {f.name}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                          style={{ textTransform: "none" }}
                          type="button"
                          tabIndex={-1}
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                      <small className="uk-text-danger">
                        {errors.state?.message}
                      </small>
                    </div>
                  </div>
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-1-2@m">
                      <label className="uk-form-label" htmlFor="city">
                        Cidade
                      </label>
                      <div
                        uk-form-custom="target: > * > span:first-child"
                        className="uk-width-1"
                      >
                        <select
                          className="uk-form-small"
                          id="city"
                          name="city"
                          defaultValue={congregation?.city}
                          ref={register}
                          disabled={isDisabled || cities.length === 0}
                        >
                          <option value="" key={`city0`}>
                            Selecione...
                          </option>
                          {cities.map((c) => {
                            return (
                              <option value={c} key={`city${c}`}>
                                {c}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                          style={{ textTransform: "none" }}
                          type="button"
                          tabIndex={-1}
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                      <small className="uk-text-danger">
                        {errors.city?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-2@s">
                      <label className="uk-form-label" htmlFor="addressLine2">
                        Complemento
                      </label>
                      <input
                        type="text"
                        id="addressLine2"
                        name="addressLine2"
                        placeholder="Complemento"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.addressLine2}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.addressLine2?.message}
                      </small>
                    </div>                    
                  </div>
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-1-2@m">
                      <label className="uk-form-label" htmlFor="pastors">
                        Dirigentes
                      </label>
                      <input
                        id="pastors"
                        name="pastors"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.pastors}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.pastors?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-2@m">
                      <label className="uk-form-label" htmlFor="contacts">
                        Contatos
                      </label>
                      <input
                        type="text"
                        id="contacts"
                        name="contacts"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.contacts}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.contacts?.message}
                      </small>
                    </div>
                    <div className="uk-width-1@m">
                      <label className="uk-form-label" htmlFor="hoursInfo">
                        Informações de Horários
                      </label>
                      <input
                        type="text"
                        id="hoursInfo"
                        name="hoursInfo"
                        className="uk-input uk-form-small"
                        defaultValue={congregation?.hoursInfo}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.hoursInfo?.message}
                      </small>
                    </div>
                  </div>
                </div>                                                              
              </div>
            </div>
            <div className="uk-width-1-4@s uk-card uk-card-default uk-card-body uk-flex uk-flex-column uk-flex-around">
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1@s uk-flex uk-flex-column uk-flex-middle">
                  {imageChurch ? (
                    <img
                      style={{ height: 120, width: 120, borderRadius: 90, marginBottom: 3 }}
                      src={
                        typeof imageChurch == "string"
                          ? `data:image/jpeg;base64,${imageChurch}`
                          : URL.createObjectURL(imageChurch)
                      }
                      alt="Imagem Igreja"
                    />
                  ) : (
                    <span uk-icon="icon: home; ratio: 4"></span>
                  )}
                  {congregation?.status != StatusType.INACTIVE && (
                    <div className="js-upload" uk-form-custom="true">
                      <input
                        type="file"
                        id="file-input-photo-church"
                        accept="image/jpg, image/png, image/jpeg, image/webp"
                        multiple={false}
                        onChange={(ev) => handleSelectImage(ev, ImageType.CHURCH)}
                        disabled={isDisabled}
                      />
                      <button
                        className="uk-button uk-button-danger uk-button-small"
                        type="button"
                        tabIndex={-1}
                        disabled={isDisabled}
                      >
                        Igreja
                        <FontAwesomeIcon
                          className="uk-margin-small-left"
                          icon={faCamera}
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1@s uk-flex uk-flex-column uk-flex-middle">
                  {imagePastors ? (
                    <img
                      style={{ height: 120, width: 120, borderRadius: 90, marginTop: 10, marginBottom: 3 }}
                      src={
                        typeof imagePastors == "string"
                          ? `data:image/jpeg;base64,${imagePastors}`
                          : URL.createObjectURL(imagePastors)
                      }
                      alt="Imagem Dirigentes"
                    />
                  ) : (
                    <span uk-icon="icon: users; ratio: 4"></span>
                  )}
                  {congregation?.status != StatusType.INACTIVE && (
                    <div className="js-upload" uk-form-custom="true">
                      <input
                        type="file"
                        id="file-input-photo-pastors"
                        accept="image/jpg, image/png, image/jpeg, image/webp"
                        multiple={false}
                        onChange={(ev) => handleSelectImage(ev, ImageType.PASTORS)}
                        disabled={isDisabled}
                      />
                      <button
                        className="uk-button uk-button-danger uk-button-small"
                        type="button"
                        tabIndex={-1}
                        disabled={isDisabled}
                      >
                        Dirigentes
                        <FontAwesomeIcon
                          className="uk-margin-small-left"
                          icon={faCamera}
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>            
          </div>
          <div className="uk-grid-small" uk-grid="true">
            <div className="uk-width-1@s">
              <label className="uk-form-label" htmlFor="note">Observações</label>
              <textarea
                id="note" 
                name="note"
                className="uk-textarea uk-form-small" 
                rows={4} 
                style={{resize: 'none'}}
                defaultValue={congregation?.note}
                autoComplete={"off"}
                ref={register}
              />
              <small className="uk-text-danger">{errors.note?.message}</small>
            </div>
          </div>
          {
            authenticationService.isAdmin() && (
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1-4@m">
                  <label className="uk-form-label" htmlFor="latitude">
                    Latitude
                  </label>
                  <input
                    type="text"
                    id="latitude"
                    name="latitude"
                    className="uk-input uk-form-small"
                    defaultValue={congregation?.latitude}
                    ref={register}
                    autoComplete={"off"}
                    onFocus={handleFocus}
                  />
                  <small className="uk-text-danger">
                    {errors.latitude?.message}
                  </small>
                </div>
                <div className="uk-width-1-4@m">
                  <label className="uk-form-label" htmlFor="longitude">
                    Longitude
                  </label>
                  <input
                    type="text"
                    id="longitude"
                    name="longitude"
                    className="uk-input uk-form-small"
                    defaultValue={congregation?.longitude}
                    ref={register}
                    autoComplete={"off"}
                    onFocus={handleFocus}
                  />
                  <small className="uk-text-danger">
                    {errors.longitude?.message}
                  </small>
                </div>                    
              </div>
            )
          }
          {congregation?.id && canChange && (
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-2@s">
                <span className="uk-text-italic uk-text-small">
                  Adicionado por:
                </span>
                <span className="uk-text-muted uk-text-small uk-margin-small-left">
                  {`${congregation?.createdBy} - ${displayDateTime(
                    congregation?.createdDate
                  )}`}
                </span>
              </div>
              {congregation.modifiedBy && (
                <div className="uk-width-1-2@s">
                  <span className="uk-text-italic uk-text-small">
                    Alterado por:
                  </span>
                  <span className="uk-text-muted uk-text-small uk-margin-small-left">
                    {`${congregation?.modifiedBy} - ${displayDateTime(
                      congregation?.modifiedDate
                    )}`}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="uk-modal-footer uk-margin-bottom">
          <div className="uk-grid-small uk-flex uk-flex-center" uk-grid="true">
            <div className="uk-text-center">
              <Link
                to="/congregacoes"
                className="uk-button uk-button-default uk-margin-left uk-width-1-1"
              >
                Voltar
              </Link>
            </div>
            <div className="uk-text-center">
              <button
                id="btn-save"
                disabled={formState.isSubmitting || isDisabled}
                className="uk-button uk-button-primary uk-margin-left uk-width-1-1"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Salvar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
