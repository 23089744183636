import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import MemberCredentialCard from "../member/member-credential-card/MemberCredentialCard";
import MemberDeleted from "../member/member-deleted/MemberDeleted";
import ScheduleList from "../schedule/schedule-list/ScheduleList";
import MeetingCard from "../meeting/meeting-card/MeetingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { toast } from "react-toastify";

export default function DashboardSecretary() {
    const [isRunning, setRunning ] = useState(false);
    const [isRunningReception, setRunningReception ] = useState(false);
    const [isRunningEnclosing, setRunningEnclosing ] = useState(false);

    const print = async () => {
        setRunning(true);
        const link = document.createElement('a');
        link.download = `Ficha de Cadastro.pdf`;

        fetch('docs/ficha-cadastro.pdf')
        .then(function (resp) { return resp.blob() })
        .then(function (blob) {
            link.href = URL.createObjectURL(blob);            
            link.click();
            window.URL.revokeObjectURL(link.href);
            setRunning(false);
            link.remove();
        }).catch(e => {
            setRunning(false);
            toast.warning("Problemas ao baixar Ficha de Cadastro");
        });  
    }

    const handleDownloadFicha = () => {
        setRunningReception(true);
        const link = document.createElement('a');
        link.download = `Pedido Recepção Eclesiástica.pdf`;

        fetch('docs/ficha-pedido-recepção.pdf')
        .then(function (resp) { return resp.blob() })
        .then(function (blob) {
            link.href = URL.createObjectURL(blob);            
            link.click();
            window.URL.revokeObjectURL(link.href);
            setRunningReception(false);
            link.remove();
        }).catch(e => {
            setRunningReception(false);
            toast.warning("Problemas ao baixar Ficha Recepção Eclesiástica");
        });   
    };

    const isButtonPrintDisable = () => {
        return isRunning || isRunningReception || isRunningEnclosing;
    }

    const handleDownloadEnclosing = () => {
        setRunningEnclosing(true);
        const link = document.createElement('a');
        link.download = `Anexo Obreiros.pdf`;

        fetch('docs/anexo-obreiro.pdf')
        .then(function (resp) { return resp.blob() })
        .then(function (blob) {
            link.href = URL.createObjectURL(blob);            
            link.click();
            window.URL.revokeObjectURL(link.href);
            setRunningEnclosing(false);
            link.remove();
        }).catch(e => {
            setRunningEnclosing(false);
            toast.warning("Problemas ao baixar Anexo Obreiro");
        });   
    };

    return (
        <div id="page-dashboad-secretary" className="uk-section uk-section-muted uk-padding-small">
            <div className="uk-container">
                <div className="uk-grid-small uk-flex-center uk-text-center uk-margin-bottom" uk-grid="true">
                    <Link className="uk-button uk-button-success uk-button-small uk-margin-left" 
                        to={`/secretaria/cartas`}>
                            Cartas Emitidas
                    </Link>
                    <Link className="uk-button uk-button-danger uk-button-small uk-margin-left" 
                        to={`/secretaria/anuidades`}>
                            Anuidades
                    </Link>
                    <div className="uk-button-group uk-button-small">
                        <button 
                            id="btn-print"
                            disabled={isButtonPrintDisable()}
                            className="uk-button uk-button-default uk-button-small" 
                            type="button"
                        >
                            <FontAwesomeIcon className="uk-margin-small-right" icon={faPrint} />
                            Impressões
                        </button>
                        <div className="uk-inline">
                            <button
                                className="uk-button uk-button-default uk-button-small" 
                                type="button"
                                disabled={isButtonPrintDisable()}
                            >
                                <span uk-icon="icon:triangle-down"></span>
                            </button>
                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); print(); }}
                                        className='print'>
                                            {isRunning && <div uk-spinner="true; ratio:0.5"></div>}
                                            Ficha de Cadastro
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); handleDownloadFicha(); }}
                                            className='print'>
                                            {isRunningReception && <div uk-spinner="true; ratio:0.5"></div>}
                                            Ficha Recepção Eclesiástica
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); handleDownloadEnclosing(); }}
                                            className='print'>
                                            {isRunningEnclosing && <div uk-spinner="true; ratio:0.5"></div>}
                                            Anexo Obreiros
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-grid-small uk-margin-bottom" uk-grid="true">
                    <div className="uk-width-1-3@l">
                        <MeetingCard />                        
                    </div>
                    <div className="uk-width-1-3@l">
                        <MemberCredentialCard />                        
                    </div>
                    <div className="uk-width-1-3@l">
                        <MemberDeleted />
                    </div>
                    <div className="uk-width-1@l">
                        <ScheduleList />
                    </div>
                </div>
            </div>
        </div>
    )
}