import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';

import './login.css';

import logo from '../../assets/images/logo-ad-belem.webp';

import { authenticationService } from '../../services/auth.service';
import toastOptions from '../../utils/toastOptions';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../components/auth/AuthContext';
import { useHistory } from 'react-router-dom';

interface UserLogin {
    username: string;
    password: string;
}

export default function Login() {
    const [seePwd, setSeePwd] = useState<boolean>(false);
    const [pwdInputType, setPwdInputType] = useState<string>('password');
    const { login } = useAuth();
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const redirecionar = urlParams.get('redirecionar');
    
    const schema = yup.object().shape({
        username: yup.string().required('Usuário obrigatório'),
        password: yup.string().required('Senha obrigatória')
    });

    const { register, handleSubmit, reset, errors, formState } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (user: UserLogin) => {
        try {
            await authenticationService.login(user.username, user.password);
            login();
            if (redirecionar) {
                history.push(redirecionar);
            }else{
                history.push('inicio');
            }
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    const handleSeePwd = (see: boolean) => {
        setSeePwd(see);
        setPwdInputType(see ? 'text' : 'password');
    }

    return (
        <>
            <div id="container-login">
                <div className="uk-margin-top">
                    <img width={200} height={250} src={logo}></img>
                </div>
                <div className="uk-flex uk-flex-center uk-margin-top" id="container-form-login">
                    <div className="uk-card uk-card-default uk-card-body">
                        <form onSubmit={handleSubmit<UserLogin>(onSubmit)} onReset={reset}>
                            <div className="uk-margin" uk-grid="true">
                                <label className="uk-form-label uk-width-1-1" htmlFor="user">Usuário</label>                            
                                <div className="uk-inline uk-width-1-1 uk-margin-remove-top">
                                    <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: user"></span>
                                    <input 
                                        className="uk-input" 
                                        type="text"
                                        name="username"
                                        id="username"
                                        ref={register}
                                    />
                                </div>
                                <small className="uk-text-danger uk-width-1-1 uk-margin-remove-top">
                                    {errors.user?.message}
                                </small>
                            </div>

                            <div className="uk-margin" uk-grid="true">
                                <label className="uk-form-label uk-width-1-1" htmlFor="password">Senha</label>
                                <div className="uk-inline uk-width-1-1 uk-margin-remove-top">
                                    {
                                        seePwd ?
                                            <a className="uk-form-icon uk-form-icon-flip"
                                                href="#"
                                                onClick={(ev) => { ev.preventDefault(); handleSeePwd(false) }}
                                            >
                                                <FontAwesomeIcon icon={faEye} />
                                            </a>
                                        :
                                            <a className="uk-form-icon uk-form-icon-flip" 
                                                href="#"
                                                onClick={(ev) => { ev.preventDefault(); handleSeePwd(true) }}
                                            >
                                                <FontAwesomeIcon icon={faEyeSlash} />
                                            </a>
                                    }
                                    <input 
                                        className="uk-input" 
                                        type={pwdInputType}
                                        name="password"
                                        id="password"
                                        ref={register}
                                    />
                                </div>
                                <small className="uk-text-danger uk-width-1-1 uk-margin-remove-top">
                                    {errors.password?.message}
                                </small>
                            </div>
                            <div className="uk-text-center">  
                                <button 
                                    disabled={formState.isSubmitting}
                                    className="uk-button uk-button-primary uk-text-center" 
                                    type="submit">     
                                    <div className="uk-inline-clip uk-transition-toggle">
                                        <div className="uk-transition-scale-up uk-transition-opaque">
                                            {formState.isSubmitting && <div uk-spinner="true"></div>}
                                            Entrar <span uk-icon="sign-in"></span>
                                        </div>
                                    </div>                               
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}