import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import Param from "../../../models/Param";
import { utils } from "../../../utils/utils";
import TablePagination from "../../../components/TablePagination";
import { faAddressCard, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MemberCredencialResponse from "../member-credential-response/MemberCredencialResponse";
import { displayDateTime } from "../../../utils/masks";
import { TypeHistory } from "../../../models/enums/TypeHistory";

interface MemberListDTO {
  id: number;
  name: string;
  ecclesiasticalFunction: any;
  congregation: any;
  historic: any;
  hasPhoto: boolean;
  hasLGPD: boolean;
}

export default function MemberCredentialRequestList() {
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMembers, setTotalMembers] = useState(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [termSearch, setTermSearch] = useState<string>("");
  const [congregations, setCongregations] = useState<Param[]>([]);
  const [congSearch, setCongSearch] = useState<number>(0);
  const [openResponse, setResponse] = useState<boolean>(false);
  const [member, setMember] = useState<any>();
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [reasonSearch, setReasonSearch] = useState<string>('');

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: MemberListDTO) => (
        <div>
          {row.ecclesiasticalFunction.id != 1 && `${row.ecclesiasticalFunction.description} `}
          {row.name}
        </div>
      )
    },
    {
      name: "Congregação",
      sortable: true,      
      cell: (row: MemberListDTO) => <span>{row.congregation.description}</span>
    },
    {
      name: "Motivo",
      sortable: true,
      cell: (row: MemberListDTO) => (
        <div className="uk-flex uk-flex-column">
          <label>{row.historic.reason}</label> 
          <label>Solicitado: {displayDateTime(row.historic.createdDate)}</label>
          {!row.hasPhoto && <label className="uk-text-danger">• Sem foto</label>}
          {!row.hasLGPD && <label className="uk-text-danger">• Sem LGPD</label>}
        </div>
      )
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: MemberListDTO, index: number) => (
        <div>          
          <Link
            to={`/membros/${row.id}`}
            target={"blank"}
            uk-tooltip="title: Visualizar"
            className='uk-margin-small-right' 
          >
            <FontAwesomeIcon icon={faSearch} />
          </Link>
          { 
            canPrint(row) && (
              <a href="#" uk-tooltip="title: Carteirinha" 
                className='uk-margin-small-right' 
                onClick={(ev) => { ev.preventDefault(); handleCard(row, index) }}
              >
                <FontAwesomeIcon icon={faAddressCard} />
              </a>
            )
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    init();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
      // Perform the search operation with delayedSearchTerm
      // This will be called only when the user stops typing for the specified delay time
      setRefresh(!refresh);
  }, [delayedSearchTerm]);
  
  const init = async () => {
    const dataCongregations = await api.get("congregations/dashboard");
    setCongregations(dataCongregations.data);
  };

  const canPrint = (row: MemberListDTO) => {
    return !isReadOnly && row.hasPhoto && row.hasLGPD;
  }

  const fetchMembers = async (page: number, limit: number) => {
    try {
      setTotalMembers(0);

      let params = {
        page: page,
        limit: limit,
        idCongregation: congSearch,
        reason: reasonSearch
      } as any;

      if (termSearch) {
        params = { ...params, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`members/credentials-requested`, {
        params: params,
      });

      setTotalMembers(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handlePrint = async () => {
    setIsRunning(true);
    try {
      const { data } = await api.post("/members/credentials-requested/report", {
        name: termSearch,
        idCongregation: congSearch,
        reason: reasonSearch
      });
      const name = `fichas-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  };

  const handleSearchByName = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
        setTermSearch(search);
    }
  }

  const handleSearch = async (congregation: number) => {
    await setCongSearch(congregation);

    setRefresh(!refresh);
  };

  const handleCard = async (member: MemberListDTO, index: number) => {
    setResponse(true);
    setMember(member);
  }

  const afterHandleSubmitCredential = () => {
    setResponse(false);
    setMember(null);
    setRefresh(!refresh);
  }
  
  const afterCloseCredentialModal = () => {
    setResponse(false);
    setMember(null);
  }

  const handleReason = async (reason: string) => {
    await setReasonSearch(reason);

    setRefresh(!refresh);
  };

  return (
    <div id="page-member-no-card-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Credenciais Solicitadas
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/secretaria"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-flex uk-flex-middle">
            <div className="uk-margin-right">Total: {totalMembers}</div>
            <button
              className="uk-button uk-button-primary uk-button-small uk-flex uk-flex-middle"
              disabled={isRunning || totalMembers == 0}
              onClick={() => handlePrint()}
            >
              <span>
                Imprimir {totalMembers > 0 && totalMembers} Ficha
                {totalMembers > 0 && totalMembers > 1 && "s"}
              </span>
              {isRunning ? (
                <div
                  className="uk-margin-small-left"
                  uk-spinner="true; ratio:0.5"
                ></div>
              ) : (
                <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} />
              )}
            </button>
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-3@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearchByName(ev.target.value);
                }}
              />
            </div>
            <div className="uk-flex uk-flex-middle uk-width-1-3@m">
              <label
                className="uk-form-label uk-margin-right"
                htmlFor="congregationId"
              >
                Congregação:
              </label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-flex-1"
              >
                <select
                  className="uk-form-small"
                  id="congregationId"
                  name="congregationId"
                  value={congSearch}
                  onChange={(e) => handleSearch(+(e.target.value))}
                >
                  <option value="" key={`congregation0`}>
                    Todas
                  </option>
                  {congregations.map((f) => {
                    return (
                      <option value={f.id} key={`congregation${f.id}`}>
                        {f.description}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
            <div className="uk-flex uk-flex-middle uk-width-1-3@m">
              <label
                className="uk-form-label uk-margin-right"
                htmlFor="reason"
              >
                Motivo:
              </label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-flex-1"
              >
                <select
                  className="uk-form-small"
                  id="reason"
                  name="reason"
                  value={reasonSearch}
                  onChange={(e) => handleReason(e.target.value)}
                >
                  <option value="" key={`reason0`}>
                    Todos
                  </option>
                  <option value={TypeHistory.FIRST_CREDENTIAL} key={`reason1`}>{TypeHistory.FIRST_CREDENTIAL}</option>
                  <option value={TypeHistory.CREDENTIAL_BAPTISM} key={`reason2`}>{TypeHistory.CREDENTIAL_BAPTISM}</option>
                  <option value={TypeHistory.CREDENTIAL_LOST} key={`reason3`}>{TypeHistory.CREDENTIAL_LOST}</option>
                  <option value={TypeHistory.CREDENTIAL_DUE} key={`reason4`}>{TypeHistory.CREDENTIAL_DUE}</option>
                  <option value={TypeHistory.CREDENTIAL_CHANGE_FUNCTION} key={`reason5`}>{TypeHistory.CREDENTIAL_CHANGE_FUNCTION}</option>
                  <option value={TypeHistory.CREDENTIAL_AMEND} key={`reason6`}>{TypeHistory.CREDENTIAL_AMEND}</option>
                  <option value={TypeHistory.CREDENTIAL_RECEPTION} key={`reason7`}>{TypeHistory.CREDENTIAL_RECEPTION}</option>
                  <option value={TypeHistory.CREDENCIAL_OTHER} key={`reason8`}>{TypeHistory.CREDENCIAL_OTHER}</option>
                </select>
                <button
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchMembers}
            refresh={refresh}
          />
          <MemberCredencialResponse 
            open={openResponse} 
            member={member}
            afterHandleSubmit={afterHandleSubmitCredential}
            afterCloseModal={afterCloseCredentialModal}
          />
        </div>
      </div>
    </div>
  );
}
