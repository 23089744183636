import Modal from 'react-modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import Select from 'react-select';

import User from '../../../models/User';
import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UserRole } from '../../../models/enums/UserRole';
import toastOptions from '../../../utils/toastOptions';
import { authenticationService } from '../../../services/auth.service';
import UserLogin from '../../../models/UserLogin';
import { UserSystem } from '../../../models/enums/UserSystem';
import Param from '../../../models/Param';
import ISelectOption from '../../../models/ISelectOption';

interface Params {
    userId?: number;
    add: boolean;
    afterHandleSubmit: any;
    afterCloseModal: any;
}

export default function UserAddEdit(props: Params) {
    const { userId, add, afterHandleSubmit, afterCloseModal } = props;

    const [modalIsOpen, setModelOpen] = useState(false);
    const [user, setUser] = useState<User>();
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [congregations, setCongregations] = useState<Param[]>([]);
    const [optCongregations, setOptCongregations] = useState<ISelectOption[]>([]);

    Modal.setAppElement('#root');

    const schema = yup.object().shape({
        name: yup.string().required('Nome obrigatório')
            .max(100, (val) => `Máximo ${val.max} caracteres`)
            .min(3, (val) => `Mínimo ${val.min} caracteres`),
        user: yup.string().required('Usuário obrigatório'),
        email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
        role: yup.string().required('Perfil obrigatório'),
        system: yup.string().required('Sistema obrigatório')
    });

    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {        
        setReadOnly((authenticationService.currentUserValue as UserLogin).role !== UserRole.ADMIN);
        if (userId) {
            handleEdit(userId);
        } else {
            init();
        }
        setModelOpen(add);
    }, [userId, add]);

    const init = async ()=> {
        const dataCongregations = await api.get('congregations/dashboard');
        await setCongregations(dataCongregations.data);

        const opts = congregations.map(c => {
            return { value: `${c.id}`, label: c.description } as ISelectOption
        });        

        await setOptCongregations(opts);
    }

    const closeModal = () =>{
        handleCloseModal();
        reset();
        afterCloseModal();
    }

    const handleCloseModal = () => {
        setUser(undefined);
        setModelOpen(false);
        afterCloseModal();
    }
    
    const onSubmit = async (data: User, e: any) => {
        try {
            if (user?.churches) {
                data.churches = user.churches;
            }

            if (!user?.id) {
                await api.post('users', data);
                toast.success('Usuário cadastrado com sucesso!',toastOptions);
            } else {
                data.id = user.id;
                await api.put('users', data);
                toast.success('Usuário atualizado com sucesso!', toastOptions);
            }
            handleCloseModal();
            afterHandleSubmit();
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    const handleEdit = async (id: number) => {
        try {
            await init();

            const { data } = await api.get(`users/${id}`);

            await setUser(data.item);
            await setValue("name", data.item.name);            
            await setValue("user", data.item.user);
            await setValue("email", data.item.email);
            await setValue("role", data.item.role);
            await setValue("system", data.item.system);
            await setModelOpen(true);

        } catch (e: any) {
            if (e.messages) {
                e.messages.forEach((m: string) => {
                    toast.warning(m, toastOptions);
                });
            }
        }
    }

    return (
        <>        
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-small"
                shouldCloseOnOverlayClick={false}            
            > 
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">{ user?.id ? 'Editar Usuário' : 'Adicionar Usuário'}</h2>
                </div>
                <button 
                    className="uk-modal-close-default" 
                    uk-close="true" 
                    type="button" 
                    onClick={handleCloseModal}
                />
                <form onSubmit={handleSubmit<User>(onSubmit)} onReset={reset}>
                    <div className="uk-modal-body">
                        <div className="uk-grid-small" uk-grid="true">
                            <div className="uk-width-1-1">
                                <label className="uk-form-label" htmlFor="name">Nome</label>
                                <input type="text" 
                                    id="name" 
                                    name="name" 
                                    placeholder="Nome" 
                                    className="uk-input uk-form-small" 
                                    defaultValue={user?.name}
                                    ref={register} />
                                <small className="uk-text-danger">{errors.name?.message}</small>
                            </div>
                            <div className="uk-width-1-2@s">
                                <label className="uk-form-label" htmlFor="email">E-mail</label>
                                <input type="text" 
                                    id="email" 
                                    name="email" 
                                    placeholder="E-mail" 
                                    className="uk-input uk-form-small"
                                    defaultValue={user?.email}
                                    ref={register} />
                                <small className="uk-text-danger">{errors.email?.message}</small>
                            </div>
                            <div className="uk-width-1-4@s">
                                <label className="uk-form-label" htmlFor="user">Usuário</label>
                                <input type="text" 
                                    id="user" 
                                    name="user" 
                                    placeholder="Usuário" 
                                    className="uk-input uk-form-small"
                                    defaultValue={user?.user}
                                    ref={register}
                                />
                                <small className="uk-text-danger">{errors.user?.message}</small>
                            </div>
                            <div className="uk-width-1-4@s">                                   
                                <div className="uk-form-controls">
                                    <label className="uk-form-label" htmlFor="role">Perfil</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="role" 
                                            placeholder="Perfil"
                                            name="role"
                                            defaultValue={user?.role}
                                            ref={register}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value={UserRole.ADMIN}>Administrador</option>
                                            <option value={UserRole.AUX_SECRETARY}>Aux. Secretaria</option>
                                            <option value={UserRole.READ}>Leitura</option>
                                            <option value={UserRole.SECRETARY}>Secretaria</option>
                                            {
                                                authenticationService.isAdmin() && <option value={UserRole.ROUTINE}>Rotina</option>
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                                <small className="uk-text-danger">{errors.role?.message}</small>
                            </div>
                            <div className="uk-width-1-4@s">                                   
                                <div className="uk-form-controls">
                                    <label className="uk-form-label" htmlFor="role">Sistema</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="system" 
                                            placeholder="Sistema"
                                            name="system"
                                            defaultValue={user?.system}
                                            ref={register}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value={UserSystem.DASHBOARD}>Dashboard</option>
                                            <option value={UserSystem.CETADEL}>Cetadel</option>
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                                <small className="uk-text-danger">{errors.role?.message}</small>
                            </div>

                            {
                                (user && user.role != `${UserRole.ADMIN}`) && (
                                    <div className='uk-width-1-2@s'>
                                        <div className="uk-form-controls">
                                            <label className="uk-form-label" htmlFor="role">
                                                {user?.system == UserSystem.CETADEL ? 'Unidades Acesso' : 'Igrejas Acesso'}
                                            </label>
                                            <Select
                                                defaultValue={
                                                    congregations.map(c => {
                                                        if (user?.churches && user?.churches?.split(',').indexOf(`${c.id}`) >= 0) {
                                                            return { value: `${c.id}`, label: c.description } as ISelectOption
                                                        }
                                                    }).filter(o => o != undefined)
                                                }
                                                isMulti
                                                placeholder={user?.system == UserSystem.CETADEL ? 'Selecione as Unidades' : 'Selecione as Igrejas'}
                                                name="colors"
                                                options={optCongregations}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(selected)=> {user && (user.churches = selected.map(s => s && s.value).join(','))}}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                    <div className="uk-modal-footer">
                        <div className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center" uk-grid="true">
                            <button 
                                className="uk-button uk-button-default uk-modal-close uk-margin-left" 
                                onClick={handleCloseModal}
                                type="button">
                                Cancelar
                            </button>
                            <button 
                                disabled={formState.isSubmitting || isReadOnly}
                                className="uk-button uk-button-primary uk-margin-left" 
                                type="submit">                                    
                                {formState.isSubmitting && <div uk-spinner="true"></div>}
                                Salvar
                            </button>
                        </div>
                    </div>                
                </form>
            </Modal>
        </>
    )
}